import { AppConfig } from 'config';
import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

let appConfig: AppConfig;

export class MirrorService {
  static init(hostname: string = document.location.hostname) {
    appConfig = new AppConfig(hostname);

    return appConfig;
  }

  static getInstance() {
    if (!appConfig) {
      this.init();
    }

    return appConfig;
  }

  static get isProd() {
    const instance = this.getInstance();
    return instance.env === AppEnv.Prod;
  }

  static isCurrentSite(domain: AppDomain) {
    const instance = this.getInstance();
    return domain === instance.domain;
  }

  static get domain() {
    const instance = this.getInstance();
    return instance.domain;
  }

  static get domainWithoutSubdomain() {
    const instance = this.getInstance();
    return instance.siteDomainWithoutSubdomain;
  }

  static get apiUrl() {
    const instance = this.getInstance();
    return instance.apiUrl;
  }

  static get wsUrl() {
    const instance = this.getInstance();
    return instance.wsUrl;
  }

  static get originForInitialId() {
    const instance = this.getInstance();
    return instance.originForInitialId;
  }

  static get originForTranslations() {
    const instance = this.getInstance();
    return instance.originForTranslations;
  }

  static get siteName() {
    const instance = this.getInstance();
    return instance.siteName;
  }

  static get clarityId() {
    const instance = this.getInstance();
    return instance.clarityId;
  }

  static get isChatsHouse() {
    return this.isCurrentSite(AppDomain.ChatsHouse);
  }

  static resolveImagePath = (imagePath?: string | null) => {
    if (!imagePath) return '';

    if (imagePath.startsWith('https') || imagePath.includes('static/media'))
      return imagePath;

    return `https://images.${this.domainWithoutSubdomain}${imagePath}`;
  };
}
