import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { MIRRORS_THEMES } from 'themes';

import { MirrorService } from 'services/MirrorService';

import { getIsEnabledDarkThemeSelector } from 'store/common/selectors';

export const useMirrorThemeColors = () => {
  const isEnabledDarkTheme = useSelector(getIsEnabledDarkThemeSelector);

  useLayoutEffect(() => {
    const currentThemeColors = isEnabledDarkTheme
      ? MIRRORS_THEMES.dark[MirrorService.domain]
      : MIRRORS_THEMES.light[MirrorService.domain];

    if (currentThemeColors) {
      Object.entries(currentThemeColors).forEach(([name, value]) => {
        document.body.style.setProperty(`--${name}`, value);
      });
    }
  }, [isEnabledDarkTheme]);

  useEffect(() => {
    document.body.classList.add('grey-bg');

    return () => {
      document.body.classList.remove('grey-bg');
    };
  }, []);
};
