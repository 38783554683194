import React, { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setXUlidId } from 'api/httpClient';
import { useMailsDraftsMigration } from 'hooks/mails/useMailsDraftsMigration';
import { useAppMeta } from 'hooks/useAppMeta';
import { useBrowserBadge } from 'hooks/useBrowserBadge';
import { useInitializeApp } from 'hooks/useInitializeApp';
import { useInterceptors } from 'hooks/useInterceptors';
import { useMirrorThemeColors } from 'hooks/useMirrorTheme';
import { useOnlinePing } from 'hooks/useOnlinePing';
import { useOnlinePingV2 } from 'hooks/useOnlinePingV2';
import { usePageLoad } from 'hooks/usePageLoad';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import { useUserProfileUlid } from 'hooks/useUserProfileUlid';
import { useXPrevUrl } from 'hooks/useXPrevUrl';
import { useXRefererUrl } from 'hooks/useXRefererUrl';
import { getAuthenticated, getUserId } from 'store/auth/selectors';
import { setPrevPagePathname } from 'store/common/commonSlice';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';

import { SystemPopup } from 'components/sections/SystemPopups';
import { FullscreenSpinner } from 'components/shared/FullscreenSpinner';

import { AppRouter } from './AppRouter';

export const App = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { isInitialized } = useInitializeApp();
  const { userProfileUlid } = useUserProfileUlid();

  const userId = useSelector(getUserId);
  const isAuthenticated = useSelector(getAuthenticated);
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);

  const { isMigratingDrafts } = useMailsDraftsMigration();

  useXPrevUrl();
  useXRefererUrl();
  useInterceptors();
  usePageLoad(!!isAuthenticated);
  useOnlinePing(!!isAuthenticated);
  useOnlinePingV2(!!isAuthenticated);

  useAppMeta();
  useBrowserBadge();
  useMirrorThemeColors();
  useScrollRestoration();

  useEffect(() => {
    if (userId && clarity.hasStarted()) {
      clarity.identify(userId, { userProperty: userId });
    }
  }, [userId]);

  useEffect(() => {
    return () => {
      dispatch(setPrevPagePathname(pathname));
    };
  }, [dispatch, pathname]);

  useEffect(() => {
    if (userProfileUlid) setXUlidId(userProfileUlid);
  }, [userProfileUlid]);

  if (!isInitialized || isMigratingDrafts) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <AppRouter />
      {activeSystemPopupType && <SystemPopup />}
    </>
  );
};
