import React, { memo, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { NoteType } from 'types/enums/NoteType';

import { TrackingApi } from 'api/TrackingApi';
import { useChatUsers } from 'hooks/messenger/useChatUsers';

import { UserProfileTab } from 'components/sections/UserProfile/UserProfileTab';

import { NotesTab } from './submodules/NotesTab';

import css from './chatInfoWidget.module.sass';

enum ChatInfoTabs {
  ContactProfile = 'contact',
  MyProfile = 'my_profile',
  Notes = 'notes',
}

export const ChatInfoWidget: React.FC = memo(() => {
  const { contactId, userProfileUlid } = useChatUsers();

  const [selectedTab, setSelectedTab] = useState(ChatInfoTabs.ContactProfile);

  const handleTabChange = useCallback(
    (tab: ChatInfoTabs) => {
      if (!contactId) return;

      TrackingApi.trackWidget({
        widgetName: 'contact',
        eventName: 'click',
        clickOn: `tab_${tab}`,
        tabName: tab,
        contactId,
      });

      setSelectedTab(tab);
    },
    [contactId]
  );

  useEffect(() => {
    if (contactId)
      TrackingApi.trackWidget({
        widgetName: 'contact',
        eventName: 'show',
        clickOn: null,
        tabName: selectedTab,
        contactId,
      });
  }, [contactId, selectedTab]);

  return (
    <div className={css.root}>
      <div className={css.header}>
        <div
          className={cx(css.headerItem, {
            [css.headerItemActive]: selectedTab === ChatInfoTabs.ContactProfile,
          })}
          onClick={() => {
            handleTabChange(ChatInfoTabs.ContactProfile);
          }}
        >
          Contact
        </div>
        <div
          className={cx(css.headerItem, {
            [css.headerItemActive]: selectedTab === ChatInfoTabs.MyProfile,
          })}
          onClick={() => {
            handleTabChange(ChatInfoTabs.MyProfile);
          }}
        >
          My Profile
        </div>
        <div
          className={cx(css.headerItem, {
            [css.headerItemActive]: selectedTab === ChatInfoTabs.Notes,
          })}
          onClick={() => {
            handleTabChange(ChatInfoTabs.Notes);
          }}
        >
          Notes
        </div>
      </div>

      <div className={css.headerTrack}>
        <div
          className={cx(css.headerActiveTrack, {
            [css.headerActiveTrack2]: selectedTab === ChatInfoTabs.MyProfile,
            [css.headerActiveTrack3]: selectedTab === ChatInfoTabs.Notes,
          })}
        />
      </div>

      <div className={css.content} id="notesScrollContainer">
        {selectedTab === ChatInfoTabs.ContactProfile && !!contactId && (
          <UserProfileTab
            contactId={contactId}
            userProfileUlid={userProfileUlid}
          />
        )}
        {selectedTab === ChatInfoTabs.MyProfile && (
          <UserProfileTab contactId={userProfileUlid} userProfileUlid={null} />
        )}
        {selectedTab === ChatInfoTabs.Notes && !!contactId && (
          <NotesTab
            contactId={contactId}
            userProfileUlid={userProfileUlid}
            noteType={NoteType.Notes}
          />
        )}
      </div>
    </div>
  );
});
