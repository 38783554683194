import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { TrackingApi } from 'api/TrackingApi';
import { getIsUserHasUnreadNotificationsSelector } from 'store/notifications/selectors';

import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import { NotificationsList } from '../NotificationsList';

import css from './notificationsWidget.module.sass';

interface Props {
  isExpandable?: boolean;
}

export const NotificationsWidget: React.FC<Props> = memo(({ isExpandable }) => {
  const isUserHasUnreadNotifications = useSelector(
    getIsUserHasUnreadNotificationsSelector
  );

  const [isShowMore, setIsShowMore] = useState(false);

  const handleShowMore = useCallback(() => {
    if (isShowMore) {
      TrackingApi.trackWidget({
        widgetName: 'activity',
        eventName: 'click',
        clickOn: 'show_less',
      });
    } else {
      TrackingApi.trackWidget({
        widgetName: 'activity',
        eventName: 'click',
        clickOn: 'show_more',
      });
    }

    setIsShowMore((prev) => !prev);
  }, [isShowMore]);

  useEffect(() => {
    TrackingApi.trackWidget({
      widgetName: 'activity',
      eventName: 'show',
      clickOn: null,
    });
  }, []);

  return (
    <div
      className={cx(css.root, {
        [css.showMore]: isShowMore,
        [css.maxHeight100]: !isExpandable,
        [css.withTransition]: isExpandable,
      })}
    >
      <div className={css.header}>
        <h3 className={css.title}>
          <NotificationIcon className={css.icon} />
          <span>Activity </span>
          {isUserHasUnreadNotifications && (
            <div className={css.activeRectangle} />
          )}
        </h3>

        {isExpandable && (
          <BaseButton
            type={ButtonTypes.Transparent}
            className={css.showMoreBtn}
            onClick={handleShowMore}
          >
            {isShowMore ? 'Show less' : 'Show more'}
          </BaseButton>
        )}
      </div>
      <NotificationsList />
    </div>
  );
});
