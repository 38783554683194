import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PresentStatus } from 'types/enums/presents/PresentStatus';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';

import { TrackingApi } from 'api/TrackingApi';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { getPresentsRequestsCount } from 'store/common/selectors';
import {
  getIsPresentsRequestsLoadingSelector,
  getSortedPresentsRequestsSelector,
} from 'store/profile/selectors';
import { getPresentsRequestsThunk } from 'store/profile/thunks';

import { ReactComponent as PresentsIcon } from 'assets/icons/presents-requests.svg';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';

import { BasePresentRequest } from './submodules/BasePresentRequest';
import { PresentConfirmPopup } from './submodules/PresentConfirmPopup';

import css from './presentsWidget.module.sass';

export const PresentsWidget: React.FC = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedPresentRequest, setSelectedPresentRequest] =
    useState<PresentRequest | null>(null);

  const presentsRequestsCount = useSelector(getPresentsRequestsCount);
  const presentsRequests = useSelector(getSortedPresentsRequestsSelector);
  const isPresentsRequestsLoading = useSelector(
    getIsPresentsRequestsLoadingSelector
  );

  const handleRequestSelect = useCallback(
    (presentRequest: PresentRequest) => {
      if (presentRequest.id)
        TrackingApi.trackWidget({
          widgetName: 'presents_request',
          eventName: 'click',
          clickOn: String(presentRequest.id),
        });

      if (presentRequest.status === PresentStatus.Requested) {
        setSelectedPresentRequest(presentRequest);

        return;
      }

      navigate(
        `/dialogs/${presentRequest.sender.ulid_id}?${QueryKeys.XUlid}=${presentRequest.receiver.ulid_id}`
      );
    },
    [navigate]
  );

  const handleConfirmPopupClose = useCallback(() => {
    setSelectedPresentRequest(null);
  }, []);

  const activeRequests = useMemo(
    () =>
      presentsRequests.filter(
        (requestItem) => requestItem.status === PresentStatus.Requested
      ),
    [presentsRequests]
  );

  useEffect(() => {
    TrackingApi.trackWidget({
      widgetName: 'presents_request',
      eventName: 'show',
      clickOn: null,
    });
  }, []);

  useEffect(() => {
    if (!presentsRequests.length) {
      dispatch(getPresentsRequestsThunk());
    }
  }, [dispatch, presentsRequests.length]);

  return (
    <div className={css.root}>
      <div className={css.header}>
        <h3 className={css.title}>
          <PresentsIcon className={css.icon} />
          <span>Presents requests</span>

          {!!presentsRequestsCount && <div className={css.activeRectangle} />}
        </h3>
      </div>

      <div className={css.list}>
        {!isPresentsRequestsLoading && !activeRequests.length && (
          <div className={css.listEmpty}>
            <p className={css.listEmptyText}>
              You don&apos;t have any requests yet!
            </p>
          </div>
        )}

        {isPresentsRequestsLoading && (
          <div className={css.loader}>
            <Spinner type={SpinnerTypes.Dark} />
          </div>
        )}

        {!isPresentsRequestsLoading &&
          !!activeRequests.length &&
          activeRequests.map((presentsRequestItem) => (
            <BasePresentRequest
              key={presentsRequestItem.id}
              present={presentsRequestItem}
              handleSelect={handleRequestSelect}
            />
          ))}
      </div>

      {!!selectedPresentRequest && (
        <PresentConfirmPopup
          presentRequest={selectedPresentRequest}
          handleClose={handleConfirmPopupClose}
        />
      )}
    </div>
  );
});
