import { useEffect, useState } from 'react';

import { MailsApi } from 'api/MailsApi';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'helpers/localStorage';

// TODO remove this in 3 days after mails drafts v2 release

const MAILS_DRAFTS_STORAGE_KEY = 'inmailMessage';

export const useMailsDraftsMigration = () => {
  const [isMigratingDrafts, setIsMigratingDrafts] = useState(
    () => !!getLocalStorageItem(MAILS_DRAFTS_STORAGE_KEY)
  );

  useEffect(() => {
    if (!isMigratingDrafts) return;

    const mailsDraft = getLocalStorageItem(MAILS_DRAFTS_STORAGE_KEY);

    if (!mailsDraft) {
      setIsMigratingDrafts(false);

      return;
    }

    const drafts = JSON.parse(mailsDraft);

    const draftsToMigrate = Object.entries<any>(drafts)
      .map(([key, value]) => {
        const [contactId, userId] = key.split('-');

        if (!contactId || !userId || !value?.message) {
          return null;
        }
        const mediaIds =
          value.media?.map((media: any) => media?.media?.id).filter(Boolean) ||
          [];

        return {
          contactId,
          userId,
          body: value.message as string,
          photosIds: mediaIds as number[],
        };
      })
      .filter(Boolean)
      .slice(0, 3);

    Promise.allSettled(
      draftsToMigrate.map(async (draftItem) => {
        if (draftItem) await MailsApi.saveMailsDraft(draftItem);
      })
    ).then(() => {
      removeLocalStorageItem(MAILS_DRAFTS_STORAGE_KEY);
      setIsMigratingDrafts(false);
    });
  }, [isMigratingDrafts]);

  return { isMigratingDrafts };
};
