import { UserGender } from 'types/enums/UserGender';

import m1 from 'assets/photoPlaceholders/m_1_.webp';
import w1 from 'assets/photoPlaceholders/w_1_.webp';

export const getPhotoPlaceholders = (gender: UserGender = UserGender.Male) => {
  const GENDER_PLACEHOLDERS: Record<UserGender, string> = {
    [UserGender.Male]: m1,
    [UserGender.Female]: w1,
  };

  return GENDER_PLACEHOLDERS[gender];
};
