import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';

import { TrackingApi } from 'api/TrackingApi';
import { useActiveUserSelect } from 'hooks/useActiveUserSelect';
import { ProfileSelectParams } from 'store/systemPopup/systemPopupSlice';

import { Popup } from 'components/shared/Popup';

import { ConversationProfileSelect } from './submodules/ConversationProfileSelect';
import { MainProfileSelect } from './submodules/MainProfileSelect';

import css from './profileSelectPopup.module.sass';

interface Props {
  params: ProfileSelectParams;
  onClose: () => void;
}

export const ProfileSelectPopup: React.FC<Props> = ({ params, onClose }) => {
  const { handleActiveUserSelect } = useActiveUserSelect();

  const handleProfileSelect = useCallback(
    (id: string | null) => {
      TrackingApi.trackWidget({
        widgetName: 'tu_profile_switcher',
        eventName: 'click',
        clickOn: id || 'ALL_USERS',
      });

      handleActiveUserSelect({ userId: id, path: params.destinationPage });

      onClose();
    },
    [handleActiveUserSelect, onClose, params.destinationPage]
  );

  useEffect(() => {
    TrackingApi.trackWidget({
      widgetName: 'tu_profile_switcher',
      eventName: 'show',
      clickOn: null,
    });
  }, []);

  return (
    <Popup
      popupClassName={cx(css.root, { [css.mainRoot]: params.isMain })}
      onBackdropCLick={onClose}
    >
      {params.isMain && (
        <MainProfileSelect
          params={params}
          onClose={onClose}
          onSelect={handleProfileSelect}
        />
      )}

      {!params.isMain && (
        <ConversationProfileSelect
          params={params}
          onSelect={handleProfileSelect}
        />
      )}
    </Popup>
  );
};
