import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Centrifuge, Subscription } from 'centrifuge';

import { MirrorService } from 'services/MirrorService';

import { useWSConnectionTokenQuery } from 'hooks/queries/useWSConnectionTokenQuery';
import { useWebSocketHandlers } from 'hooks/useWebSocketHandlers';
import { getAuthenticated, getUserId } from 'store/auth/selectors';

enum WSChannel {
  Messages = 'messages',
  Notifications = 'notifications',
  Limits = 'limits',
  Mails = 'inmails',
  System = 'system',
  MediaAccess = 'media_access',
  Presents = 'real_gift',
}

interface WebSocketSubscriptions {
  messageSubscription: Subscription | null;
  notificationSubscription: Subscription | null;
  limitsSubscription: Subscription | null;
  mailsSubscription: Subscription | null;
  systemSubscription: Subscription | null;
  mediaAccessSubscription: Subscription | null;
  presentsSubscription: Subscription | null;
}

export const WebSocketContext = createContext<WebSocketSubscriptions>({
  messageSubscription: null,
  notificationSubscription: null,
  limitsSubscription: null,
  mailsSubscription: null,
  systemSubscription: null,
  mediaAccessSubscription: null,
  presentsSubscription: null,
});

export const WebSocketProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const userId = useSelector(getUserId);
  const isAuthenticated = useSelector(getAuthenticated);

  const [isConnected, setIsConnected] = useState(false);

  const centrifuge = useMemo(() => {
    if (!isAuthenticated || !userId) return null;

    return new Centrifuge(`${MirrorService.wsUrl}/connection/websocket`);
  }, [isAuthenticated, userId]);

  const {
    messageHandler,
    notificationsHandler,
    limitsHandler,
    mailsHandler,
    systemHandler,
    mediaAccessHandler,
    presentsHandler,
  } = useWebSocketHandlers();

  const {
    messageSubscription,
    notificationSubscription,
    limitsSubscription,
    mailsSubscription,
    systemSubscription,
    mediaAccessSubscription,
    presentsSubscription,
  } = useMemo<WebSocketSubscriptions>(() => {
    if (!centrifuge)
      return {
        messageSubscription: null,
        notificationSubscription: null,
        limitsSubscription: null,
        mailsSubscription: null,
        systemSubscription: null,
        mediaAccessSubscription: null,
        presentsSubscription: null,
      };

    return {
      messageSubscription: centrifuge.newSubscription(
        `${WSChannel.Messages}#${userId}`
      ),

      notificationSubscription: centrifuge.newSubscription(
        `${WSChannel.Notifications}#${userId}`
      ),

      limitsSubscription: centrifuge.newSubscription(
        `${WSChannel.Limits}#${userId}`
      ),

      mailsSubscription: centrifuge.newSubscription(
        `${WSChannel.Mails}#${userId}`
      ),
      systemSubscription: centrifuge.newSubscription(
        `${WSChannel.System}#${userId}`
      ),
      mediaAccessSubscription: centrifuge.newSubscription(
        `${WSChannel.MediaAccess}#${userId}`
      ),
      presentsSubscription: centrifuge.newSubscription(
        `${WSChannel.Presents}#${userId}`
      ),
    };
  }, [centrifuge, userId]);

  const contextValue = useMemo<WebSocketSubscriptions>(
    () => ({
      messageSubscription,
      notificationSubscription,
      limitsSubscription,
      mailsSubscription,
      systemSubscription,
      mediaAccessSubscription,
      presentsSubscription,
    }),
    [
      limitsSubscription,
      mailsSubscription,
      mediaAccessSubscription,
      messageSubscription,
      notificationSubscription,
      systemSubscription,
      presentsSubscription,
    ]
  );

  const clearCentrifugeSubscriptions = useCallback(() => {
    messageSubscription?.unsubscribe();
    notificationSubscription?.unsubscribe();
    limitsSubscription?.unsubscribe();
    mailsSubscription?.unsubscribe();
    systemSubscription?.unsubscribe();
    mediaAccessSubscription?.unsubscribe();
    presentsSubscription?.unsubscribe();

    centrifuge?.removeSubscription(messageSubscription);
    centrifuge?.removeSubscription(notificationSubscription);
    centrifuge?.removeSubscription(mailsSubscription);
    centrifuge?.removeSubscription(limitsSubscription);
    centrifuge?.removeSubscription(systemSubscription);
    centrifuge?.removeSubscription(mediaAccessSubscription);
    centrifuge?.removeSubscription(presentsSubscription);

    centrifuge?.disconnect();

    setIsConnected(false);
  }, [
    messageSubscription,
    notificationSubscription,
    limitsSubscription,
    mailsSubscription,
    systemSubscription,
    mediaAccessSubscription,
    presentsSubscription,
    centrifuge,
  ]);

  const { data } = useWSConnectionTokenQuery({
    enabled: !!isAuthenticated,
  });

  useEffect(() => {
    if (!!centrifuge && !!data?.token && !isConnected) {
      centrifuge.setToken(data?.token);
      centrifuge.connect();

      setIsConnected(true);
    }
  }, [centrifuge, data?.token, isConnected]);

  useEffect(() => {
    messageSubscription?.subscribe();
    notificationSubscription?.subscribe();
    limitsSubscription?.subscribe();
    mailsSubscription?.subscribe();
    systemSubscription?.subscribe();
    mediaAccessSubscription?.subscribe();
    presentsSubscription?.subscribe();

    return () => {
      messageSubscription?.unsubscribe();
      notificationSubscription?.unsubscribe();
      limitsSubscription?.unsubscribe();
      mailsSubscription?.unsubscribe();
      systemSubscription?.unsubscribe();
      mediaAccessSubscription?.unsubscribe();
      presentsSubscription?.unsubscribe();
    };
  }, [
    centrifuge,
    limitsSubscription,
    mailsSubscription,
    mediaAccessSubscription,
    messageSubscription,
    notificationSubscription,
    systemSubscription,
    presentsSubscription,
  ]);

  const handlePostMessage = useCallback(
    (msg: any) => {
      if (!msg?.data?.socketEvent || !msg?.data?.channel) return;

      switch (msg.data.channel) {
        case WSChannel.Messages:
          messageHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.Mails:
          mailsHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.Limits:
          limitsHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.Notifications:
          notificationsHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.System:
          systemHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.MediaAccess:
          mediaAccessHandler(msg?.data?.socketEvent);
          break;
        case WSChannel.Presents:
          presentsHandler(msg?.data?.socketEvent);
          break;

        default:
          break;
      }
    },
    [
      limitsHandler,
      mailsHandler,
      mediaAccessHandler,
      messageHandler,
      notificationsHandler,
      systemHandler,
      presentsHandler,
    ]
  );

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [handlePostMessage]);

  useEffect(() => {
    if (isAuthenticated === false) {
      clearCentrifugeSubscriptions();
    }
  }, [clearCentrifugeSubscriptions, isAuthenticated]);

  useEffect(() => {
    messageSubscription?.on('publication', messageHandler);

    return () => {
      messageSubscription?.removeListener('publication', messageHandler);
    };
  }, [messageHandler, messageSubscription]);

  useEffect(() => {
    notificationSubscription?.on('publication', notificationsHandler);

    return () => {
      notificationSubscription?.removeListener(
        'publication',
        notificationsHandler
      );
    };
  }, [notificationSubscription, notificationsHandler]);

  useEffect(() => {
    mailsSubscription?.on('publication', mailsHandler);

    return () => {
      mailsSubscription?.removeListener('publication', mailsHandler);
    };
  }, [mailsSubscription, mailsHandler]);

  useEffect(() => {
    limitsSubscription?.on('publication', limitsHandler);

    return () => {
      limitsSubscription?.removeListener('publication', limitsHandler);
    };
  }, [limitsSubscription, limitsHandler]);

  useEffect(() => {
    systemSubscription?.on('publication', systemHandler);

    return () => {
      systemSubscription?.removeListener('publication', systemHandler);
    };
  }, [systemHandler, systemSubscription]);

  useEffect(() => {
    mediaAccessSubscription?.on('publication', mediaAccessHandler);

    return () => {
      mediaAccessSubscription?.removeListener(
        'publication',
        mediaAccessHandler
      );
    };
  }, [mediaAccessHandler, mediaAccessSubscription]);

  useEffect(() => {
    presentsSubscription?.on('publication', presentsHandler);

    return () => {
      presentsSubscription?.removeListener('publication', presentsHandler);
    };
  }, [presentsHandler, presentsSubscription]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
