import { createSelector } from '@reduxjs/toolkit';

import { ChatDialogHideStatus } from 'types/enums/chat/ChatDialogHideStatus';

import { sortArrayByFieldDate } from 'helpers/dates';
import { getActiveUserProfileId } from 'store/auth/selectors';
import { RootState } from 'store/rootReducer';

const getCounters = (state: RootState) => state.messenger?.dialogsCounters;

export const getDialogsCounters = createSelector(
  getActiveUserProfileId,
  getCounters,
  (activeUserProfileId, dialogsCounters) => {
    if (activeUserProfileId) return dialogsCounters[activeUserProfileId] || {};

    return Object.values(dialogsCounters || {}).reduce(
      (acc, counters) => ({
        all: acc.all + counters.all,
        saved: acc.saved + counters.saved,
        new: acc.new + counters.new,
        matches: acc.matches + counters.matches,
      }),
      { all: 0, saved: 0, new: 0, matches: 0 }
    );
  }
);

export const getDialogs = (state: RootState) => {
  return state.messenger.dialogs;
};

export const getDialogsFilters = (state: RootState) => {
  return state.messenger.dialogsFilters;
};

export const getIsDialogsLoading = (state: RootState) => {
  return state.messenger.dialogsLoading;
};

export const getIsMessagesLoading = (state: RootState) =>
  state.messenger.messagesLoading;

export const getStickerPacks = (state: RootState) =>
  state.messenger.stickerPacks;

export const getIsStickerPacksLoading = (state: RootState) =>
  state.messenger.stickerPacksLoading;

export const getGifts = (state: RootState) => state.messenger.gifts;

export const getIsGiftsLoading = (state: RootState) =>
  state.messenger.giftsLoading;

export const getDialog =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return !contactId || !userId
      ? null
      : state.messenger.messages[`${contactId}-${userId}`]?.dialog || null;
  };

const getMessages =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return !contactId || !userId
      ? []
      : state.messenger.messages[`${contactId}-${userId}`]?.messages || [];
  };

export const getSortedMessages = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(getMessages({ contactId, userId }), (messages) =>
    sortArrayByFieldDate('sent_at', messages)
  );

export const getHasMoreMessages =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    !!state.messenger.messages[`${contactId}-${userId}`]?.next;

export const getContact =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.contact;

export const getNewestDialogInfo = createSelector(getDialogs, (dialogs) => {
  if (!dialogs || dialogs.data.length === 0) {
    return undefined;
  }

  return {
    contactId: dialogs.data[0].contact?.ulid_id,
    userId: dialogs.data[0].user?.ulid_id,
  };
});

export const getChatContact =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.contact;

export const getChatUser =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.user;

export const getIsChatContactLiked =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.isLiked;

export const getIsConnection =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.isConnection;

export const getIsChatBlockedByContactSelector =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.isBlocked;

export const getChatLimits =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.messenger.messages?.[`${contactId}-${userId}`]?.limits;

const getIsEnabledLimitsTest =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return state.messenger.messages[`${contactId}-${userId}`]
      ?.isEnabledLimitsTest;
  };

const getContactSpent =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return state.messenger.messages[`${contactId}-${userId}`]?.contact?.credits
      ?.spend;
  };

export const getIsChatMediaEnabled = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(
    getChatContact({ contactId, userId }),
    getContactSpent({ contactId, userId }),
    getIsEnabledLimitsTest({ contactId, userId }),
    (contact, contactSpend, isEnabledLimitsTest) => {
      if (!contact || !contactSpend) return false;

      if (isEnabledLimitsTest) {
        return contactSpend === 20 || contactSpend === '20+';
      }

      return contactSpend === '20+';
    }
  );

export const getIsUserCanHideChatDialog = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(
    getChatLimits({ contactId, userId }),
    getDialog({ contactId, userId }),
    (chatLimits, chatDialog) =>
      !chatLimits?.message && chatDialog?.is_hide === ChatDialogHideStatus.Shown
  );

export const getIsUserCanShowChatDialog = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(
    getDialog({ contactId, userId }),
    (chatDialog) => chatDialog?.is_hide === ChatDialogHideStatus.Hidden
  );

export const getTypingContacts = (state: RootState) =>
  state.messenger.typingContacts;
