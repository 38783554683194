import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'types/interfaces/user/User';
import { UserContact } from 'types/interfaces/user/UserContact';

import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';
import {
  getUserIdFromCookies,
  removeUserIdFromCookies,
  setUserIdToCookies,
} from 'helpers/userId';

const ACTIVE_USER_ID = 'active_user_id';

interface AuthState {
  user: User | null;
  loading: boolean;
  authenticated: boolean | null;

  activeProfileId: string | null;
  profiles: UserContact[];
  profilesLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  authenticated: !!getUserIdFromCookies(),

  activeProfileId: getSessionStorageItem(ACTIVE_USER_ID),
  profiles: [],
  profilesLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
    },

    authFailed(state) {
      state.loading = false;
    },

    authSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.loading = false;
    },

    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },

    setAuthenticated(state, action: PayloadAction<{ userId: string | null }>) {
      if (action.payload.userId) {
        setUserIdToCookies(action.payload.userId);
      } else {
        removeUserIdFromCookies();
      }

      state.authenticated = !!action.payload.userId;
    },

    setProfiles(state, action: PayloadAction<UserContact[]>) {
      const profiles = action.payload;

      if (
        state.activeProfileId &&
        !profiles.find(
          (profileItem) => profileItem.ulid_id === state.activeProfileId
        )
      ) {
        state.activeProfileId = null;

        setSessionStorageItem(ACTIVE_USER_ID, null);
      }

      if (!state.activeProfileId && profiles.length === 1) {
        state.activeProfileId = profiles[0].ulid_id;
      }

      state.profiles = action.payload;
      state.profilesLoading = false;
    },

    setActiveProfileId(state, action: PayloadAction<string | null>) {
      setSessionStorageItem(ACTIVE_USER_ID, action.payload);

      state.activeProfileId = action.payload;
    },

    updateUser(state, action: PayloadAction<Partial<User>>) {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  authStart,
  authFailed,
  authSuccess,
  setUser,
  setProfiles,
  setAuthenticated,
  setActiveProfileId,
  updateUser,
} = authSlice.actions;

export const auth = authSlice.reducer;
