import { createSelector } from '@reduxjs/toolkit';

import { sortArrayByFieldDate } from 'helpers/dates';
import { getActiveUserProfileId } from 'store/auth/selectors';
import { RootState } from 'store/rootReducer';

export const getMailsChainsCounters = (state: RootState) =>
  state.inbox?.mailsChainsCounters;

const getTotalMailsChainsCounters = createSelector(
  getMailsChainsCounters,
  (mailsChainsCounters) => {
    return Object.values(mailsChainsCounters || {}).reduce(
      (acc, counters) => ({
        all: acc.all + counters.all,
        new: acc.new + counters.new,
        matches: acc.matches + counters.matches,
        featured: acc.featured + counters.featured,
      }),
      { all: 0, new: 0, matches: 0, featured: 0 }
    );
  }
);

export const getTotalMailsChainsCount = createSelector(
  getTotalMailsChainsCounters,
  (totalMailsChainsCounters) => {
    return (
      (totalMailsChainsCounters.new || 0) +
      (totalMailsChainsCounters.featured || 0)
    );
  }
);

export const getTotalNewMailsChainsCount = createSelector(
  getTotalMailsChainsCounters,
  (totalMailsChainsCounters) => {
    return totalMailsChainsCounters.new;
  }
);

export const getMailsChainsCountersByProfile = createSelector(
  getActiveUserProfileId,
  getMailsChainsCounters,
  getTotalMailsChainsCounters,
  (activeUserProfileId, mailsChainsCounters, totalMailsChainsCounters) => {
    if (activeUserProfileId) return mailsChainsCounters[activeUserProfileId];

    return totalMailsChainsCounters;
  }
);

const getMailsDrafts = (state: RootState) => {
  return state.inbox.mailsDrafts;
};

export const getSortedMailsDrafts = createSelector(
  getMailsDrafts,
  (mailsDrafts) => sortArrayByFieldDate('updated_at', mailsDrafts || [])
);

export const getIsMailsDraftsLoading = (state: RootState) =>
  state.inbox.mailsDraftsLoading;

export const getIsMailsDraftLoading = (state: RootState) =>
  state.inbox.mailsDraftLoading;

export const getMailsDraft =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mailsDraft?.[`${contactId}-${userId}`];

export const getIsMailsDraftsLimitReached = (state: RootState) =>
  state.inbox.isMailsDraftsLimitReached;

export const getNextMailsChains = (state: RootState) => {
  return state.inbox.nextMailsChains;
};

const getMailsChains = (state: RootState) => {
  return state.inbox?.mailsChains ?? [];
};

export const getSortedMailsChains = createSelector(
  getMailsChains,
  getSortedMailsDrafts,
  (mailsChains, mailsDrafts) => {
    if (mailsDrafts.length) {
      const filteredMailsChains = mailsChains.filter(
        (chainItem) =>
          !mailsDrafts.find(
            (draftItem) =>
              draftItem.contact?.ulid_id === chainItem.contact?.ulid_id &&
              draftItem.user?.ulid_id === chainItem.user?.ulid_id
          )
      );

      return sortArrayByFieldDate('sent_at', filteredMailsChains || []);
    }

    return sortArrayByFieldDate('sent_at', mailsChains || []);
  }
);

export const getIsMailsChainsLoading = (state: RootState) => {
  return state.inbox.mailsChainsLoading;
};

const getMails =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return !contactId
      ? null
      : state.inbox.mails[`${contactId}-${userId}`]?.messages;
  };

export const getSortedMails = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(getMails({ contactId, userId }), (mails) =>
    sortArrayByFieldDate('sent_at', mails || [])
  );

export const getMailsFilters = (state: RootState) => state.inbox.mailsFilters;

export const getHasMoreMails =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    !!state.inbox.mails[`${contactId}-${userId}`]?.next;

export const getIsMailsLoading = (state: RootState) => state.inbox.mailsLoading;

export const getMailsChainContact =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.contact;

export const getMailsChainUser =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.user;

const getIsEnabledLimitsTest =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return state.inbox.mails[`${contactId}-${userId}`]?.isEnabledLimitsTest;
  };

const getContactSpend =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return state.inbox.mails[`${contactId}-${userId}`]?.contact?.credits?.spend;
  };

export const getIsMailsChatMediaEnabled = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) =>
  createSelector(
    getMailsChainUser({ contactId, userId }),
    getContactSpend({ contactId, userId }),
    getIsEnabledLimitsTest({ contactId, userId }),
    (contact, contactSpend, isEnabledLimitsTest) => {
      if (!contact || !contactSpend) return false;

      if (isEnabledLimitsTest) {
        return contactSpend === 20 || contactSpend === '20+';
      }

      return contactSpend === '20+';
    }
  );

export const getIsMailsChatBlockedByContactSelector =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.isBlocked;

export const getIsMailsChainContactLiked =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.isLiked;

export const getMailsChatLimits =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.limits;

export const getIsConnection =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) =>
    state.inbox.mails?.[`${contactId}-${userId}`]?.isConnection;
