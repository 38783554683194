import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

export const getLoading = (state: RootState) => state.auth.loading;
export const getAuthenticated = (state: RootState) => state.auth.authenticated;

export const getUser = (state: RootState) => state.auth.user;
export const getUserId = (state: RootState) => state.auth.user?.ulid_id;

export const getProfiles = (state: RootState) => state.auth.profiles;
export const getIsProfilesLoading = (state: RootState) =>
  state.auth.profilesLoading;

export const getActiveUserProfileId = (state: RootState) =>
  state.auth.activeProfileId;

export const getActiveUserProfile = createSelector(
  getProfiles,
  getActiveUserProfileId,
  (profiles, activeUserProfileId) => {
    if (profiles.length === 1) return profiles[0];

    return profiles.find((profile) => profile.ulid_id === activeUserProfileId);
  }
);

export const getIsEnabledProfileSwitcher = createSelector(
  getProfiles,
  (profiles) => {
    if (!profiles.length || profiles.length === 1) return false;

    return true;
  }
);
