import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

interface SiteConfig {
  id: number;
  groupId: number;
  domain: AppDomain;
  clarityId: Partial<
    Record<AppEnv.Prod | AppEnv.Dev | AppEnv.Stage | AppEnv.Pid, string>
  >;
}

export const SITES_CONFIGS: Record<string, SiteConfig> = {
  ChatsHouse: {
    id: 4,
    groupId: 2,
    domain: AppDomain.ChatsHouse,
    clarityId: {
      [AppEnv.Prod]: 'q1ec73cgnk',
    },
  },
};

export const DEFAULT_SITE_ID = SITES_CONFIGS.ChatsHouse.id;
export const DEFAULT_DEV_SITE_DOMAIN = 'spiddy.co';
export const DEFAULT_PRODUCTION_SITE_DOMAIN = SITES_CONFIGS.ChatsHouse.domain;

export const INMAIL_MESSAGES_DRAFTS = 'inmailMessage';
