import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import { usePreventBgScroll } from 'hooks/usePreventBgScroll';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Portal } from 'components/shared/Portal';

import fadeTransition from 'styles/transitions/fade.module.sass';

import css from './popup.module.sass';

interface Props {
  zIndex?: number;
  popupClassName?: string;
  bodyClassName?: string;
  isOverlayBlurred?: boolean;
  isIndented?: boolean;
  isMobileStyle?: boolean;
  onClose?: () => void;
  onBackdropCLick?: () => void;
}

export const Popup: React.FC<PropsWithChildren<Props>> = memo(
  ({
    zIndex,
    popupClassName,
    bodyClassName,
    onBackdropCLick,
    isOverlayBlurred,
    isIndented = true,
    isMobileStyle,
    onClose,
    children,
  }) => {
    const preventPropagation = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
      },
      []
    );

    usePreventBgScroll({ enabled: true });

    useLayoutEffect(() => {
      const rootNode = document.getElementById('root');

      if (isOverlayBlurred && rootNode) {
        rootNode.classList.add(css.blured);
      }

      return () => {
        if (isOverlayBlurred && rootNode) {
          rootNode.classList.remove(css.blured);
        }
      };
    }, [isOverlayBlurred]);

    return (
      <Portal>
        <div
          className={cx(css.root, {
            [css.rootTypeMobileStyle]: isMobileStyle,
          })}
          style={{ zIndex }}
          onClick={onBackdropCLick}
        >
          <CSSTransition in appear timeout={200} classNames={fadeTransition}>
            <section
              className={cx(css.popup, popupClassName, {
                [css.popupTypeIndented]: isIndented,
              })}
              onClick={preventPropagation}
            >
              {onClose && (
                <BaseButton
                  className={css.closeBtn}
                  type={ButtonTypes.Transparent}
                  onClick={onClose}
                >
                  <CloseIcon className={css.closeIcon} />
                </BaseButton>
              )}
              <div
                className={cx(css.body, bodyClassName, {
                  [css.bodyTypeIndented]: isIndented,
                })}
              >
                {children}
              </div>
            </section>
          </CSSTransition>
        </div>
      </Portal>
    );
  }
);
