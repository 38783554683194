import React from 'react';
import { useSelector } from 'react-redux';

import { MirrorService } from 'services/MirrorService';

import { getProfiles } from 'store/auth/selectors';
import { ProfileSelectParams } from 'store/systemPopup/systemPopupSlice';

import { ReactComponent as ArrowIcon } from 'assets/profileIcons/nav-back.svg';

import { CloseButton } from 'components/shared/CloseButton';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './mainProfileSelect.module.sass';

interface Props {
  params: ProfileSelectParams;
  onSelect: (id: string | null) => void;
  onClose: () => void;
}

export const MainProfileSelect: React.FC<Props> = ({
  params,
  onSelect,
  onClose,
}) => {
  const profiles = useSelector(getProfiles);

  const finalProfilesList =
    params.triggerPage === 'presentsButton'
      ? profiles.filter(
          (profile) =>
            params.customSelectedField && profile[params.customSelectedField]
        )
      : profiles;

  return (
    <>
      <div className={css.header}>
        <p>Choose your profile</p>
        <CloseButton className={css.closeButton} onClick={onClose} />
      </div>

      <ul className={css.profileList}>
        {!!finalProfilesList.length &&
          finalProfilesList.map((profile) => (
            <li
              key={profile.ulid_id}
              className={css.profileItem}
              onClick={() => onSelect(profile.ulid_id)}
            >
              <div className={css.flexContainer}>
                <UserAvatar
                  isActiveUser
                  shouldShowOnlineBadge
                  isOnline
                  v2Placeholder
                  url={MirrorService.resolveImagePath(
                    profile.main_photo?.profile_url
                  )}
                  size={48}
                />
                <p className={css.flexContainer}>
                  <span className={css.name}>{profile?.name}, </span>
                  <span>{profile?.age}</span>
                </p>
              </div>

              <div className={css.arrowIcon}>
                <ArrowIcon />
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
