import { SoundNotification } from 'types/interfaces/Notifications';

import { httpClient } from './httpClient';

type AccountSettings = {
  email: {
    name: string;
    value: string;
  };
  id: {
    name: string;
    value: number;
  };
  notifications: {
    name: string;
    value: boolean;
  };
  password: {
    name: string;
    value: string;
  };
};

export interface ContactUsPayload {
  email?: string;
  message: string;
}

export const SettingsApi = {
  async fetchSettings() {
    const { data } = await httpClient.get<AccountSettings>('/user/settings');

    return data;
  },

  async sendEmailVerification() {
    const { data } = await httpClient.patch(
      '/user/settings/email-verification'
    );

    return data;
  },

  async sendEmailToSupport(payload: ContactUsPayload) {
    const { data } = await httpClient.post('/contact-us', payload);

    return data;
  },

  async unsubscribeFromMailing() {
    const { data } = await httpClient.get('/user/do-not-sell');

    return data;
  },

  async fetchTerms(termsSource: string) {
    const { data } = await httpClient.get<{ terms: string; policy: string }>(
      termsSource
    );

    return { terms: data.terms || data.policy || '' };
  },

  async fetchSiteInfo() {
    const { data } = await httpClient.get<{ address: string }>('/site-info');

    return data;
  },

  async updateNotificationsSoundFlag(payload: SoundNotification) {
    return httpClient.post('/settings/sound-notification', payload, {
      headers: {
        'X-ULID': null,
      },
    });
  },
};
