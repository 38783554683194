import React, { memo, useCallback } from 'react';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { PresentStatus } from 'types/enums/presents/PresentStatus';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';

import { formatItemDateByDay } from 'helpers/dates';
import { getPhotoPlaceholders } from 'helpers/photoPlaceholders';

import { UserAvatar } from 'components/shared/UserAvatar';
import { UserPaymentStatus } from 'components/shared/UserPaymentStatus';

import { PresentStatusBadge } from '../PresentStatusBadge';

import css from './basePresentRequest.module.sass';

interface Props {
  present: PresentRequest;
  handleSelect: (presentRequest: PresentRequest) => void;
}

export const BasePresentRequest: React.FC<Props> = memo(
  ({ present, handleSelect }) => {
    const handlePresentClick = useCallback(() => {
      handleSelect(present);
    }, [handleSelect, present]);

    return (
      <div
        className={cx(css.root, {
          [css.rootTypeNew]: present.status === PresentStatus.Requested,
        })}
        onClick={handlePresentClick}
      >
        <div className={css.imageWrapper}>
          <UserAvatar
            isActiveUser
            shouldShowUser
            shouldShowOnlineBadge
            url={
              MirrorService.resolveImagePath(
                present?.sender?.main_photo?.profile_url
              ) ?? getPhotoPlaceholders(present?.sender?.gender)
            }
            userUrl={
              MirrorService.resolveImagePath(
                present?.receiver?.main_photo?.profile_url
              ) ?? getPhotoPlaceholders(present?.sender?.gender)
            }
            size={50}
            className={css.image}
            isOnline={!!present?.sender?.is_online}
          />
        </div>
        <div className={css.info}>
          <div className={css.title}>
            <UserPaymentStatus spendCredits={present?.sender?.credits?.spend} />
            <span>{present.sender.name}</span>
            <p className={css.date}>
              {formatItemDateByDay(present.created_at)}
            </p>
          </div>
          <div className={css.description}>
            <p>sent you a present! Please confirm it.</p>
            <PresentStatusBadge status={present.status} />
          </div>
        </div>
      </div>
    );
  }
);
