import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveUserProfileId, getUserId } from 'store/auth/selectors';

import { useSystemSearchQueries } from './useSystemSearchQueries';

export const useUserProfileUlid = () => {
  const defaultUserId = useSelector(getUserId);
  const activeUserProfileId = useSelector(getActiveUserProfileId);

  const { xUlidQuery } = useSystemSearchQueries();

  const userProfileUlid = useMemo(
    () => xUlidQuery.value || activeUserProfileId || defaultUserId || '',
    [activeUserProfileId, defaultUserId, xUlidQuery.value]
  );

  return { userProfileUlid };
};
