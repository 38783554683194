import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserContact } from 'types/interfaces/user/UserContact';

export enum SystemPopupTypes {
  ProfileSelect = 1,
}

// ? we can add top left position props
interface BaseProfileSelectParams {
  triggerPage: 'dialogs' | 'inmails' | 'finder';
  isMain?: boolean;
  destinationPage: string | null;
}

interface SpecificProfileSelectParams
  extends Omit<BaseProfileSelectParams, 'triggerPage'> {
  triggerPage: 'presentsButton';
  hasCustomProfileList: boolean;
  customSelectedField: keyof UserContact;
}

export type ProfileSelectParams =
  | BaseProfileSelectParams
  | SpecificProfileSelectParams;

type SystemPopupParams = ProfileSelectParams;

interface SystemPopupState {
  type: SystemPopupTypes | null;
  params: SystemPopupParams | null;
}

const initialState: SystemPopupState = {
  type: null,
  params: null,
};

const systemPopupSlice = createSlice({
  name: 'systemPopup',
  initialState,
  reducers: {
    setActiveSystemPopup(
      state,
      action: PayloadAction<{
        type: SystemPopupTypes;
        params?: SystemPopupParams;
      }>
    ) {
      state.type = action.payload.type;
      state.params = action.payload.params || null;
    },

    closeSystemPopup(state) {
      state.type = null;
      state.params = null;
    },

    resetState() {
      return initialState;
    },
  },
});

export const { setActiveSystemPopup, closeSystemPopup, resetState } =
  systemPopupSlice.actions;

export const systemPopup = systemPopupSlice.reducer;
