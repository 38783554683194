import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { getProfiles } from 'store/auth/selectors';
import {
  getNewNotificationsCount,
  getTotalNewDialogsCount,
} from 'store/common/selectors';
import { getTotalNewMailsChainsCount } from 'store/mails/selectors';

import { ReactComponent as FindIcon } from 'assets/icons/find.svg';
import { ReactComponent as MailsIcon } from 'assets/icons/mail.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { NotificationsPopup } from 'components/sections/Notifications/NotificationsPopup';

import { ProfileListPopup } from '../ProfileListPopup';

import css from './privatePageMobileNavigation.module.sass';

export const PrivatePageMobileNavigation = memo(() => {
  const profiles = useSelector(getProfiles);
  const newNotificationsCount = useSelector(getNewNotificationsCount);
  const totalNewDialogsCount = useSelector(getTotalNewDialogsCount);
  const totalNewMailsChainsCount = useSelector(getTotalNewMailsChainsCount);

  const [isShownNotificationsList, setIsShownNotificationsList] =
    useState(false);
  const [isProfileListPopupOpen, setIsProfileListPopupOpen] = useState(false);

  const toggleProfileListModal = useCallback(() => {
    if (isShownNotificationsList) {
      setIsShownNotificationsList(false);
    }

    setIsProfileListPopupOpen(!isProfileListPopupOpen);
  }, [isProfileListPopupOpen, isShownNotificationsList]);

  const toggleNotificationsList = useCallback(() => {
    if (isProfileListPopupOpen) {
      setIsProfileListPopupOpen(false);
    }

    setIsShownNotificationsList((prev) => !prev);
  }, [isProfileListPopupOpen]);

  return (
    <>
      <nav className={css.root}>
        <NavLink
          to="/finder"
          end
          className={({ isActive }) =>
            cx(css.navLink, { [css.navLinkActive]: isActive })
          }
        >
          <FindIcon className={css.navLinkIcon} />
          <span>Finder</span>
        </NavLink>
        <NavLink
          to="/dialogs"
          end
          className={({ isActive }) =>
            cx(css.navLink, { [css.navLinkActive]: isActive })
          }
        >
          <MessageIcon className={css.navLinkIcon} />
          <span>Messages</span>
          {!!totalNewDialogsCount && (
            <div className={css.rectangleWithCounter}>
              {totalNewDialogsCount > 99 ? '99+' : totalNewDialogsCount}
            </div>
          )}
        </NavLink>
        <NavLink
          to="/mails"
          className={({ isActive }) =>
            cx(css.navLink, css.navLinkWithMargin, {
              [css.navLinkActive]: isActive,
            })
          }
        >
          <MailsIcon className={css.navLinkIcon} />
          <span>Mails</span>
          {!!totalNewMailsChainsCount && (
            <div className={css.rectangleWithCounter}>
              {totalNewMailsChainsCount > 99 ? '99+' : totalNewMailsChainsCount}
            </div>
          )}
        </NavLink>
        <BaseButton
          onClick={toggleNotificationsList}
          type={ButtonTypes.Transparent}
          className={cx(css.navLink, {
            [css.navLinkActive]: isShownNotificationsList,
          })}
        >
          <NotificationIcon className={css.navLinkIcon} />
          <span>Activity</span>
          {!!newNotificationsCount && <div className={css.rectangle} />}
        </BaseButton>
        {profiles?.length ? (
          <BaseButton
            className={cx(css.navLink, {
              [css.navLinkActive]: isShownNotificationsList,
            })}
            type={ButtonTypes.Transparent}
            onClick={toggleProfileListModal}
          >
            <>
              <ProfileIcon className={css.navLinkIcon} />
              <span>My profile</span>
            </>
          </BaseButton>
        ) : (
          <NavLink
            to="/my-profile"
            className={({ isActive }) =>
              cx(css.navLink, {
                [css.navLinkActive]: isActive,
              })
            }
          >
            <ProfileIcon className={css.navLinkIcon} />
            <span>My profile</span>
          </NavLink>
        )}

        {isProfileListPopupOpen && (
          <ProfileListPopup
            isMobile
            onClose={toggleProfileListModal}
            source="/my-profile"
            profiles={profiles}
          />
        )}
      </nav>

      <NotificationsPopup
        onClose={toggleNotificationsList}
        isOpen={isShownNotificationsList}
      />
    </>
  );
});
