import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';

import { getActiveUserProfile, getProfiles } from 'store/auth/selectors';
import {
  getTotalNewDialogsCount,
  getUnreadCounters,
} from 'store/common/selectors';
import {
  getMailsChainsCounters,
  getTotalNewMailsChainsCount,
} from 'store/mails/selectors';
import { ProfileSelectParams } from 'store/systemPopup/systemPopupSlice';

import { UserAvatar } from 'components/shared/UserAvatar';

import css from './conversationProfileSelect.module.sass';

interface Props {
  params: ProfileSelectParams;
  onSelect: (id: string | null) => void;
}

export const ConversationProfileSelect: React.FC<Props> = ({
  params,
  onSelect,
}) => {
  const profiles = useSelector(getProfiles);
  const activeUserProfile = useSelector(getActiveUserProfile);
  const unreadCounters = useSelector(getUnreadCounters);
  const totalNewDialogsCount = useSelector(getTotalNewDialogsCount);
  const totalNewMailsChainsCount = useSelector(getTotalNewMailsChainsCount);
  const mailsChainsCounters = useSelector(getMailsChainsCounters);

  const totalCounter = useMemo(() => {
    if (params.triggerPage === 'inmails') return totalNewMailsChainsCount;

    return totalNewDialogsCount;
  }, [params.triggerPage, totalNewDialogsCount, totalNewMailsChainsCount]);

  return (
    <div className={css.profileList}>
      <div
        key="default"
        className={cx(css.profileItem, {
          [css.profileItemActive]: !activeUserProfile,
        })}
        onClick={() => onSelect(null)}
      >
        <UserAvatar isActiveUser v2Placeholder size={48} />
        <p className={css.name}>All users</p>
        {!!totalCounter && <p className={css.notification}>{totalCounter}</p>}
      </div>

      {!!profiles.length &&
        profiles.map((profile) => (
          <div
            key={profile.ulid_id}
            className={cx(css.profileItem, {
              [css.profileItemActive]:
                activeUserProfile?.ulid_id === profile.ulid_id,
            })}
            onClick={() => onSelect(profile.ulid_id)}
          >
            <UserAvatar
              isActiveUser
              shouldShowOnlineBadge
              isOnline
              v2Placeholder
              url={MirrorService.resolveImagePath(
                profile.main_photo?.profile_url
              )}
              size={48}
            />
            <p className={css.name}>
              <span>{profile?.name}, </span>
              <span>{profile?.age}</span>
            </p>

            {params.triggerPage === 'dialogs' &&
              !!unreadCounters[profile.ulid_id]?.[params.triggerPage] && (
                <p className={css.notification}>
                  {unreadCounters[profile.ulid_id][params.triggerPage]}
                </p>
              )}

            {params.triggerPage === 'inmails' &&
              !!mailsChainsCounters[profile.ulid_id]?.new && (
                <p className={css.notification}>
                  {mailsChainsCounters[profile.ulid_id].new}
                </p>
              )}
          </div>
        ))}
    </div>
  );
};
