import { AppDispatch, AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { ChatDialogDisplayType } from 'types/enums/chat/ChatDialogDisplayType';
import { ChatDialogSaveStatus } from 'types/enums/chat/ChatDialogSaveStatus';
import { ChatDialogTab } from 'types/enums/chat/ChatDialogTab';
import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { ChatMessageType } from 'types/enums/chat/ChatMessageType';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatSticker } from 'types/interfaces/chat/ChatSticker';
import { Photo } from 'types/interfaces/Photo';

import { DialogsApi } from 'api/DialogsApi';
import { getErrorMessageWithDefault } from 'helpers/errors';
import { setCommonToastError } from 'store/common/commonSlice';

import {
  addDialog,
  addDialogs,
  addMessage,
  addMessages,
  hideDialog,
  openGift,
  removeDialog,
  setDialogs,
  setDialogsCounters,
  setDialogsLoading,
  setGifts,
  setGiftsLoading,
  setMessages,
  setMessagesLoading,
  setStickerPacks,
  setStickerPacksLoading,
  unHideDialog,
  updateDialog,
  updateDialogReadStatus,
  updateDialogSaveStatus,
  updateDialogWithNewMessage,
  updateMessageStatus,
} from './messengerSlice';

export const fetchDialogsThunk =
  (payload: ChatDialogFilters & { userId: string | null }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setDialogsLoading(true));

    const dialogsResponse = await DialogsApi.fetchDialogsWithFilters(payload);

    dispatch(setDialogsCounters(dialogsResponse.tab_counters));
    dispatch(setDialogs(dialogsResponse));

    dispatch(setDialogsLoading(false));
  };

export const fetchOneDialogThunk =
  (payload: { contactId: string; userId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const dialogsResponse = await DialogsApi.fetchOneDialog({
      contactId: payload.contactId,
      userId: payload.userId,
    });

    dispatch(addDialog(dialogsResponse.data));
  };

export const fetchMoreDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const nextUrl = getState().messenger.dialogs.next;

    const dialogsResponse = await DialogsApi.fetchNextDialogs(nextUrl);

    dispatch(addDialogs(dialogsResponse));
  };

export const updateDialogHideStatusThunk =
  (payload: {
    contactId: string;
    userId: string;
    shouldHide: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const data = await DialogsApi.updateDialogHideStatus(
      payload.contactId,
      payload.shouldHide
    );

    if (data.status && payload.shouldHide) {
      dispatch(
        hideDialog({
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      if (dialogsFilters.tab !== ChatDialogTab.Hidden)
        payload.cb && payload.cb();
    }

    if (data.status && !payload.shouldHide)
      dispatch(
        unHideDialog({
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );
  };

export const updateDialogSaveStatusThunk =
  (payload: {
    isSaved: ChatDialogSaveStatus;
    contactId: string;
    userId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    await DialogsApi.updateDialogSaveStatus({
      isSaved: payload.isSaved,
      contactId: payload.contactId,
    });

    dispatch(
      updateDialogSaveStatus({
        isSaved: payload.isSaved,
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );
  };

export const fetchMessagesThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMessagesLoading(true));

    const messagesResponse = await DialogsApi.fetchMessages(payload.contactId);

    dispatch(setMessagesLoading(false));
    dispatch(
      setMessages({
        user: messagesResponse.user,
        contact: messagesResponse.contact,
        dialog: messagesResponse.dialog,
        messages: messagesResponse.messages,
        next: messagesResponse.next,
        limits: messagesResponse.limits,
        isLiked: messagesResponse.is_liked,
        isBlocked: messagesResponse.blocked_me,
        isConnection: messagesResponse?.connection_status,
        isEnabledLimitsTest: messagesResponse?.is_enabled_media_limits_55,
      })
    );

    dispatch(setMessagesLoading(false));
  };

export const fetchMoreMessagesThunk =
  (payload: { contactId: string; userId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId, userId } = payload;
    const { messenger } = getState();

    const nextUrl = messenger.messages[`${contactId}-${userId}`].next;
    const isMessagesLoading = messenger.messagesLoading;

    if (!nextUrl || isMessagesLoading) return;

    dispatch(setMessagesLoading(true));

    const messagesResponse = await DialogsApi.fetchMoreMessages(nextUrl);

    dispatch(setMessagesLoading(false));
    dispatch(
      addMessages({
        contactId,
        userId,
        messages: messagesResponse.messages,
        next: messagesResponse.next,
      })
    );
  };

export const readIncomingMessagesThunk =
  (payload: {
    messageId: string;
    contactId: string;
    userId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    await DialogsApi.readIncomingMessages({
      contactId: payload.contactId,
      messageId: payload.messageId,
    });

    dispatch(
      updateDialogReadStatus({
        contactId: payload.contactId,
        userId: payload.userId,
        isIncoming: true,
      })
    );
  };

export const sendTextMessageThunk =
  (payload: { contactId: string; userId: string; body: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: payload.body,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Text,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: ChatMessageType.General,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    try {
      await DialogsApi.sendTextMessage({
        contactId: payload.contactId,
        body: payload.body,
        frontMessageId,
      });
    } catch (error: any) {
      dispatch(
        updateMessageStatus({
          message: {
            front_message_id: frontMessageId,
            status: ChatMessageStatus.Failed,
          },
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      dispatch(setCommonToastError(getErrorMessageWithDefault(error)));
    }
  };

export const sendGiftMessageThunk =
  (payload: {
    contactId: string;
    userId: string;
    gift: ChatGift;
    message: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: payload.message || null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Gift,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: ChatMessageType.General,
          gift: payload.gift,
          is_gift_opened: false,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    try {
      await DialogsApi.sendGiftMessage({
        contactId: payload.contactId,
        giftId: payload.gift.id,
        body: payload.message,
        frontMessageId,
      });
    } catch (error: any) {
      dispatch(
        updateMessageStatus({
          message: {
            front_message_id: frontMessageId,
            status: ChatMessageStatus.Failed,
          },
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      dispatch(setCommonToastError(getErrorMessageWithDefault(error)));
    }
  };

export const sendStickerMessageThunk =
  (payload: {
    contactId: string;
    userId: string;
    sticker: ChatSticker;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Sticker,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: ChatMessageType.General,
          sticker: payload.sticker,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    try {
      await DialogsApi.sendStickerMessage({
        contactId: payload.contactId,
        stickerId: payload.sticker.id,
        frontMessageId,
      });
    } catch (error: any) {
      dispatch(
        updateMessageStatus({
          message: {
            front_message_id: frontMessageId,
            status: ChatMessageStatus.Failed,
          },
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      dispatch(setCommonToastError(getErrorMessageWithDefault(error)));
    }
  };

export const sendPhotoMessageThunk =
  (payload: { contactId: string; userId: string; photo: Photo }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Photo,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: ChatMessageType.General,
          photo: payload.photo,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    try {
      await DialogsApi.sendPhotoMessage({
        contactId: payload.contactId,
        photoId: payload.photo.id,
        format: ChatMessageFormat.Photo,
        frontMessageId,
      });
    } catch (error: any) {
      dispatch(
        updateMessageStatus({
          message: {
            front_message_id: frontMessageId,
            status: ChatMessageStatus.Failed,
          },
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      dispatch(setCommonToastError(getErrorMessageWithDefault(error)));
    }
  };

export const addNewMessageFromSocketThunk =
  (payload: {
    message: ChatMessage;
    contactId: string;
    userId: string;
  }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { activeProfileId } = getState().auth;
    const { dialogs } = getState().messenger;

    dispatch(addMessage(payload));

    if (activeProfileId && payload.userId !== activeProfileId) return;

    const hasDialogWith =
      (contactId: string, userId: string) => (dialog: ChatDialog) =>
        dialog.contact?.ulid_id === contactId &&
        dialog.user?.ulid_id === userId;

    if (dialogs.data.some(hasDialogWith(payload.contactId, payload.userId))) {
      dispatch(updateDialogWithNewMessage(payload));
    } else {
      dispatch(
        fetchOneDialogThunk({
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );
    }
  };

export const fetchGiftsThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setGiftsLoading(true));

    const { data: giftsResponse } = await DialogsApi.fetchGifts();

    dispatch(setGifts(giftsResponse));
    dispatch(setGiftsLoading(false));
  };

export const fetchStickerPacksThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setStickerPacksLoading(true));

    const { data: stickerPacksResponse } = await DialogsApi.fetchStickerPacks();

    dispatch(setStickerPacks(stickerPacksResponse));
    dispatch(setStickerPacksLoading(false));
  };

export const updateDialogTabs =
  (payload: {
    user_id: string;
    contact_id: string;
    display_type: ChatDialogDisplayType;
    tabs: string[];
  }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    dispatch(
      updateDialog({
        user_id: payload.user_id,
        contact_id: payload.contact_id,
        display_type: payload.display_type,
      })
    );

    if (dialogsFilters?.tab && !payload.tabs.includes(dialogsFilters?.tab)) {
      dispatch(
        removeDialog({ contactId: payload.contact_id, userId: payload.user_id })
      );
    }
  };

export const openGiftThunk =
  (payload: {
    contactId: string;
    userId: string;
    messageId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    DialogsApi.openGift(payload);

    dispatch(openGift(payload));
  };
