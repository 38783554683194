import React from 'react';
import cx from 'classnames';

import { transformActivityTimeToLabel } from './utils';

import css from './lastActivityLabel.module.sass';

interface Props {
  lastActiveAt: string;
  hasBackground?: boolean;
}

export const LastActivityLabel: React.FC<Props> = ({
  lastActiveAt,
  hasBackground = false,
}) => {
  const { minutes, hours } = transformActivityTimeToLabel(lastActiveAt);

  if (hours < 1) {
    return (
      <div
        className={cx(css.lastActivitiLabel, {
          [css.labelBackground]: hasBackground,
        })}
      >
        <span>Last seen:</span>{' '}
        <span className={css.boldText}>{minutes}m ago</span>
      </div>
    );
  }

  if (hours < 48) {
    return (
      <div
        className={cx(css.lastActivitiLabel, {
          [css.labelBackground]: hasBackground,
        })}
      >
        <span>Last seen:</span>{' '}
        <span className={css.boldText}>{hours}h ago</span>
      </div>
    );
  }

  return (
    <div
      className={cx(css.lastActivitiLabel, {
        [css.labelBackground]: hasBackground,
      })}
    >
      Offline
    </div>
  );
};
