import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';

import { useActiveUserSelect } from 'hooks/useActiveUserSelect';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { getActiveUserProfile, getProfiles } from 'store/auth/selectors';
import {
  getPresentsRequestsCount,
  getTotalNewDialogsCount,
} from 'store/common/selectors';
import {
  getTotalMailsChainsCount,
  getTotalNewMailsChainsCount,
} from 'store/mails/selectors';

import { ReactComponent as FindIcon } from 'assets/icons/find.svg';
import { ReactComponent as IcebreakersIcon } from 'assets/icons/icebreakers.svg';
import { ReactComponent as MailsIcon } from 'assets/icons/mail.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as PresentsIcon } from 'assets/icons/presents.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import { Container } from '../Container';
import { Logo } from '../Logo';
import { UserAvatar } from '../UserAvatar';

import css from './privatePageDesktopNavigation.module.sass';

export const PrivatePageDesktopNavigation: React.FC = memo(() => {
  const totalNewDialogsCount = useSelector(getTotalNewDialogsCount);
  const totalMailsChainsCount = useSelector(getTotalMailsChainsCount);
  const totalNewMailsChainsCount = useSelector(getTotalNewMailsChainsCount);
  const profiles = useSelector(getProfiles);
  const activeUserProfile = useSelector(getActiveUserProfile);
  const presentsRequestsCount = useSelector(getPresentsRequestsCount);

  const profilesWithPresents = useMemo(
    () => profiles.filter((profileItem) => profileItem.real_gift_available),
    [profiles]
  );

  const { handleOpenProfilesPopup } = useActiveUserSelect();

  return (
    <nav className={css.root}>
      <Container className={css.container}>
        <Logo dark />

        <div className={css.navLinksLeft}>
          <NavLink
            to="/finder"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <FindIcon className={css.navLinkLeftIcon} />
            <span>Finder</span>
          </NavLink>
          <NavLink
            to="/dialogs"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <MessageIcon className={css.navLinkLeftIcon} />
            <span>Messages</span>
            {!!totalNewDialogsCount && (
              <div className={css.rectangleLeftLink}>
                {totalNewDialogsCount > 99 ? '99+' : totalNewDialogsCount}
              </div>
            )}
          </NavLink>
          <NavLink
            to="/mails"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <MailsIcon className={css.navLinkLeftIcon} />
            <span>Mails</span>
            {!!totalNewMailsChainsCount && (
              <div className={css.rectangleLeftLink}>
                {totalNewMailsChainsCount > 99
                  ? '99+'
                  : totalNewMailsChainsCount}
              </div>
            )}
            {!!totalMailsChainsCount && !totalNewMailsChainsCount && (
              <div className={css.dot} />
            )}
          </NavLink>
          <NavLink
            to="/icebreakers"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <IcebreakersIcon className={css.navLinkLeftIcon} />
            <span>Icebreakers</span>
          </NavLink>

          {profiles?.length > 1 && !!profilesWithPresents.length && (
            <BaseButton
              className={css.navLinkLeft}
              type={ButtonTypes.Transparent}
              onClick={() =>
                handleOpenProfilesPopup({
                  params: {
                    triggerPage: 'presentsButton',
                    isMain: true,
                    destinationPage: '/presents',
                    hasCustomProfileList: true,
                    customSelectedField: 'real_gift_available',
                  },
                })
              }
            >
              <PresentsIcon className={css.navLinkLeftIcon} />
              <span>Presents</span>

              {!!presentsRequestsCount && (
                <div className={css.rectangleLeftLink}>
                  {presentsRequestsCount > 99 ? '99+' : presentsRequestsCount}
                </div>
              )}
            </BaseButton>
          )}

          {profiles?.length === 1 && activeUserProfile?.real_gift_available && (
            <NavLink
              to="/presents"
              className={({ isActive }) =>
                cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
              }
            >
              <PresentsIcon className={css.navLinkLeftIcon} />
              <span>Presents</span>

              {!!presentsRequestsCount && (
                <div className={css.rectangleLeftLink}>
                  {presentsRequestsCount > 99 ? '99+' : presentsRequestsCount}
                </div>
              )}
            </NavLink>
          )}
        </div>

        <div className={css.divider} />

        <div className={css.navLinksRight}>
          {(activeUserProfile || profiles?.length === 1) && (
            <NavLink
              to={`/my-profile?${QueryKeys.XUlid}=${
                activeUserProfile?.ulid_id || profiles?.[0]?.ulid_id
              }`}
              className={({ isActive }) =>
                cx(css.navLinkRight, { [css.navLinkActive]: isActive })
              }
            >
              <UserAvatar
                isActiveUser
                v2Placeholder
                size={30}
                url={MirrorService.resolveImagePath(
                  activeUserProfile?.main_photo?.profile_url
                )}
              />
            </NavLink>
          )}

          {profiles?.length > 1 && !activeUserProfile && (
            <div className={css.defaultProfile}>
              <ProfileIcon className={css.navLinkRightIcon} />

              <div className={css.profileError}>
                <span>!</span> First, choose a profile
              </div>
            </div>
          )}
        </div>
      </Container>
    </nav>
  );
});
