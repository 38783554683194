import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MailsTab } from 'types/enums/mails/MailsTab';
import { MailStatus } from 'types/enums/mails/MailStatus';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsChainsTabCounters } from 'types/interfaces/mails/MailsChainsTabCounters';
import { MailsFilters } from 'types/interfaces/mails/MailsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

interface MailsList {
  next: string | null;
  isLiked: boolean;
  isBlocked: boolean;
  isConnection: boolean;
  isEnabledLimitsTest: boolean;
  user: UserContact;
  contact: UserContact;
  messages: Mail[];
  limits: ChatLimits;
}

const MAILS_FILTERS_STORAGE_KEY = 'mails_filters';

const DEFAULT_MAILS_FILTERS = {
  search: '',
  tab: MailsTab.All,
};

const initMailsFilters = () => {
  try {
    const mailsFiltersLS = getLocalStorageItem(MAILS_FILTERS_STORAGE_KEY);

    if (!mailsFiltersLS) {
      return DEFAULT_MAILS_FILTERS;
    }

    return JSON.parse(mailsFiltersLS);
  } catch (error) {
    return DEFAULT_MAILS_FILTERS;
  }
};

interface InboxState {
  mailsFilters: MailsFilters;

  mailsChains: MailsChain[];
  mailsChainsLoading: boolean;
  mailsChainsCounters: Record<string, MailsChainsTabCounters>;
  nextMailsChains: string | null;

  mails: Record<string, MailsList>;
  mailsLoading: boolean;

  mailsDraft: Record<string, MailsChain>;
  mailsDraftLoading: boolean;
  mailsDrafts: MailsChain[];
  mailsDraftsLoading: boolean;
  isMailsDraftsLimitReached: boolean;
}

const initialState: InboxState = {
  mailsFilters: initMailsFilters(),

  mailsChains: [],
  mailsChainsLoading: true,
  mailsChainsCounters: {},
  nextMailsChains: null,

  mails: {},
  mailsLoading: true,

  mailsDraft: {},
  mailsDraftLoading: true,
  mailsDrafts: [],
  mailsDraftsLoading: true,
  isMailsDraftsLimitReached: false,
};

const updateMailsChainWithNewMailMapper =
  (newMail: Mail, contactId: string, userId: string) =>
  (mailsChain: MailsChain) => {
    const isDialogWithProperContact =
      mailsChain.contact.ulid_id === contactId &&
      mailsChain.user.ulid_id === userId;

    if (!isDialogWithProperContact) {
      return mailsChain;
    }

    return {
      ...mailsChain,
      body: newMail.body,
      status: newMail.status,
      sent_at: newMail.sent_at,
      is_incoming: newMail.is_incoming,
      unread_count: newMail.is_incoming ? mailsChain.unread_count + 1 : 0,
    };
  };

const setMailRead = () => (message: Mail) => {
  if (message.is_incoming || message.status === MailStatus.Failed) {
    return message;
  }

  return {
    ...message,
    status: MailStatus.Read,
    paid: true,
  };
};

const mailsSlice = createSlice({
  name: 'mails',
  initialState,
  reducers: {
    updateMailsChatContactLimits(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        limits: Partial<ChatLimits>;
      }>
    ) {
      const { userId, contactId, limits } = action.payload;

      if (state.mails[`${contactId}-${userId}`]) {
        state.mails[`${contactId}-${userId}`].limits = {
          ...state.mails[`${contactId}-${userId}`].limits,
          ...limits,
        };
      }
    },

    setMailsFilters(state, action: PayloadAction<Partial<MailsFilters>>) {
      state.mailsFilters = { ...state.mailsFilters, ...action.payload };

      setLocalStorageItem(
        MAILS_FILTERS_STORAGE_KEY,
        JSON.stringify({ ...state.mailsFilters, ...action.payload })
      );
    },

    // ? ***************** MAILS  ACTIONS START *****************
    setMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = action.payload;
    },

    resetMailsChains(state) {
      state.mailsChains = initialState.mailsChains;
      state.mailsChainsLoading = initialState.mailsChainsLoading;
      state.mailsDraftsLoading = initialState.mailsDraftsLoading;
      state.mailsDrafts = initialState.mailsDrafts;
      state.nextMailsChains = initialState.nextMailsChains;
    },

    setMailsChainsCounters(
      state,
      action: PayloadAction<Record<string, MailsChainsTabCounters>>
    ) {
      state.mailsChainsCounters = {
        ...state.mailsChainsCounters,
        ...action.payload,
      };
    },

    setMailsChainsLoading(state, action: PayloadAction<boolean>) {
      state.mailsChainsLoading = action.payload;
    },

    setNextMailsChains(state, action: PayloadAction<string | null>) {
      state.nextMailsChains = action.payload;
    },

    addMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = [...action.payload, ...state.mailsChains];
    },

    addMailsChain(state, action: PayloadAction<MailsChain>) {
      state.mailsChains = [action.payload, ...state.mailsChains];
    },

    updateMailsChainWithNewMail(
      state,
      action: PayloadAction<{
        message: Mail;
        userId: string;
        contactId: string;
      }>
    ) {
      const { message: newMail, userId, contactId } = action.payload;

      const isExistInMailsChain = Boolean(
        state.mailsChains.find(
          (mailsChain) => mailsChain.contact.ulid_id === contactId
        )
      );

      if (isExistInMailsChain) {
        state.mailsChains = state.mailsChains.map(
          updateMailsChainWithNewMailMapper(newMail, contactId, userId)
        );
      }
    },

    // ? ***************** MAILS CHAINS ACTIONS *****************

    // ? ***************** MAILS ACTIONS *****************
    setMails(state, action: PayloadAction<MailsList>) {
      const { user, contact } = action.payload;

      state.mails[`${contact.ulid_id}-${user.ulid_id}`] = action.payload;
    },

    setIsMailsLoading(state, action: PayloadAction<boolean>) {
      state.mailsLoading = action.payload;
    },

    addMail(
      state,
      action: PayloadAction<{
        message: Mail;
        userId: string;
        contactId: string;
      }>
    ) {
      const { message, userId, contactId } = action.payload;

      if (
        state.mails[`${contactId}-${userId}`] &&
        state.mails[`${contactId}-${userId}`].messages
      ) {
        let isMailAlreadyExist = false;

        const newMails = state.mails[`${contactId}-${userId}`].messages.map(
          (messageItem) => {
            if (messageItem.id === message.id) {
              isMailAlreadyExist = true;

              return message;
            }
            return messageItem;
          }
        );

        state.mails[`${contactId}-${userId}`].messages = isMailAlreadyExist
          ? newMails
          : [...newMails, message];
      } else {
        state.mails[`${contactId}-${userId}`] = {
          ...state.mails[`${contactId}-${userId}`],
          messages: [message],
          next: null,
        };
      }
    },

    addMails(
      state,
      action: PayloadAction<{
        messages: Mail[];
        userId: string;
        contactId: string;
        next: string | null;
      }>
    ) {
      const { messages, userId, contactId, next } = action.payload;

      const allMails =
        state.mails[`${contactId}-${userId}`].messages.concat(messages);

      state.mails[`${contactId}-${userId}`] = {
        ...state.mails[`${contactId}-${userId}`],
        messages: allMails,
        next,
      };
    },

    markMailsAsRead(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      if (
        !state.mails[`${contactId}-${userId}`] ||
        !state.mails[`${contactId}-${userId}`].messages
      ) {
        return;
      }

      state.mails[`${contactId}-${userId}`].messages = state.mails[
        `${contactId}-${userId}`
      ].messages.map(setMailRead());
    },

    updateMailsChainContact(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        contact: Partial<UserContact>;
      }>
    ) {
      const { userId, contactId, contact } = action.payload;

      if (state.mails[`${contactId}-${userId}`]?.contact)
        state.mails[`${contactId}-${userId}`].contact = {
          ...state.mails[`${contactId}-${userId}`].contact,
          ...contact,
        };
    },

    markMailsChainAsBlocked(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isBlocked = true;
    },

    markMailsChainAsRead(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      state.mailsChains = state.mailsChains.map((chain) => {
        if (
          chain.user.ulid_id === userId &&
          chain.contact.ulid_id === contactId
        ) {
          return { ...chain, paid: true };
        }

        return chain;
      });

      state.mailsDrafts = state.mailsDrafts.map((chain) => {
        if (
          chain.user.ulid_id === userId &&
          chain.contact.ulid_id === contactId
        ) {
          return { ...chain, paid: true };
        }

        return chain;
      });
    },

    markMailsChainAsConnection(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      const isExistInMailsChains = Boolean(
        state.mailsChains.find((mailsChainItem) => {
          return (
            mailsChainItem.contact?.ulid_id === contactId &&
            mailsChainItem.user?.ulid_id === userId
          );
        })
      );

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isConnection = true;

      if (isExistInMailsChains)
        state.mailsChains = state.mailsChains.map((mailsChainItem) => {
          if (
            mailsChainItem.contact?.ulid_id === contactId &&
            mailsChainItem.user?.ulid_id === userId
          ) {
            return {
              ...mailsChainItem,
              connection_status: true,
            };
          }

          return mailsChainItem;
        });

      if (!isExistInMailsChains)
        state.mailsDrafts = state.mailsDrafts.map((mailsDraftItem) => {
          if (
            mailsDraftItem.contact?.ulid_id === contactId &&
            mailsDraftItem.user?.ulid_id === userId
          ) {
            return {
              ...mailsDraftItem,
              connection_status: true,
            };
          }

          return mailsDraftItem;
        });
    },

    mailsChainChangeLimit(
      state,
      action: PayloadAction<{
        limits: number;
        userId: string;
        contactId: string;
      }>
    ) {
      const { userId, contactId, limits } = action.payload;

      state.mailsChains = state.mailsChains.map((chain) => {
        if (
          chain.user.ulid_id === userId &&
          chain.contact.ulid_id === contactId
        ) {
          return { ...chain, limits };
        }

        return chain;
      });
    },

    setMailsDrafts(state, action: PayloadAction<MailsChain[]>) {
      state.mailsDrafts = action.payload;
    },

    setIsMailsDraftsLoading(state, action: PayloadAction<boolean>) {
      state.mailsDraftsLoading = action.payload;
    },

    addMailsDraft(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
        draft: MailsChain;
      }>
    ) {
      const { userId, contactId, draft } = action.payload;

      state.mailsDraft[`${contactId}-${userId}`] = draft;
    },

    setIsMailsDraftLoading(state, action: PayloadAction<boolean>) {
      state.mailsDraftLoading = action.payload;
    },

    setIsMailsDraftLimitReached(state, action: PayloadAction<boolean>) {
      state.isMailsDraftsLimitReached = action.payload;
    },

    createMailsDraft(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        updatedDraft: MailsChain;
      }>
    ) {
      const { updatedDraft } = action.payload;

      state.mailsDrafts = state.mailsDrafts.map((draftItem) => {
        if (
          draftItem.contact.ulid_id === updatedDraft.contact.ulid_id &&
          draftItem.user.ulid_id === updatedDraft.user.ulid_id
        ) {
          return { ...draftItem, ...updatedDraft };
        }
        return draftItem;
      });
    },

    updateMailsDraft(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        updatedDraft: MailsChain;
      }>
    ) {
      const { updatedDraft } = action.payload;

      state.mailsDrafts = state.mailsDrafts.map((draftItem) => {
        if (
          draftItem.contact.ulid_id === updatedDraft.contact.ulid_id &&
          draftItem.user.ulid_id === updatedDraft.user.ulid_id
        ) {
          return { ...draftItem, ...updatedDraft };
        }
        return draftItem;
      });
    },

    removeMailsDraft(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
      }>
    ) {
      const { userId, contactId } = action.payload;

      state.mailsDrafts = state.mailsDrafts.filter(
        (draftItem) =>
          draftItem.contact.ulid_id !== contactId ||
          draftItem.user.ulid_id !== userId
      );
    },

    // ? ***************** MAILS ACTIONS *****************

    // ? ***************** CONTACT ACTIONS *****************
    setMailsContactSpend(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;

      Object.keys(state.mails).forEach((key) => {
        if (state.mails[key].contact?.ulid_id === contactId)
          state.mails[key].contact.credits.spend = state.mails[key]
            .isEnabledLimitsTest
            ? 20
            : '20+';
      });
    },

    likeMailsChainContact(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        isLiked: boolean;
      }>
    ) {
      const { userId, contactId, isLiked } = action.payload;

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isLiked = isLiked;
    },
    // ? ***************** CONTACT ACTIONS *****************

    resetState() {
      return initialState;
    },
  },
});

export const {
  updateMailsChatContactLimits,

  setMailsChains,
  resetMailsChains,
  setMailsChainsLoading,
  addMailsChains,
  setMailsChainsCounters,
  setNextMailsChains,
  setMailsFilters,
  addMailsChain,

  setMails,
  setIsMailsLoading,
  addMail,
  addMails,
  addMailsDraft,
  setIsMailsDraftLoading,
  setMailsDrafts,
  setIsMailsDraftsLoading,
  updateMailsDraft,
  removeMailsDraft,
  setIsMailsDraftLimitReached,

  updateMailsChainContact,
  updateMailsChainWithNewMail,
  markMailsAsRead,
  markMailsChainAsBlocked,
  markMailsChainAsConnection,
  markMailsChainAsRead,
  mailsChainChangeLimit,

  setMailsContactSpend,
  likeMailsChainContact,

  resetState,
} = mailsSlice.actions;

export const inbox = mailsSlice.reducer;
