import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SoundNotification } from 'types/interfaces/Notifications';
import { UnreadCounters } from 'types/interfaces/UnreadCounters';

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'helpers/localStorage';

const DARK_THEME = 'dark_theme';

interface FeatureFlags {
  isEnabledIcebreakersChains: boolean;
  isEnabledPresents: boolean;
}
interface CommonState {
  loading: boolean;
  presentsRequestsCount: number;
  unreadCount: Record<string, UnreadCounters>;
  prevPagePathname: string;
  currentLang: string;
  supportedLangs: string[];
  featureFlags: FeatureFlags;
  isEnabledDarkTheme: boolean;
  commonToastErrorMessage: string | null;
  sound_notification: SoundNotification;
}

const initialState: CommonState = {
  loading: false,
  presentsRequestsCount: 0,
  unreadCount: {},
  prevPagePathname: '',
  currentLang: 'en',
  supportedLangs: [],
  featureFlags: {
    isEnabledIcebreakersChains: false,
    isEnabledPresents: false,
  },
  isEnabledDarkTheme: !!getLocalStorageItem(DARK_THEME),
  commonToastErrorMessage: null,
  sound_notification: {
    received_message: true,
    sent_message: true,
    received_mail: true,
    sent_mail: true,
    received_like: true,
    sent_like: true,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setInitState(
      state,
      action: PayloadAction<
        Omit<
          CommonState,
          'prevPagePathname' | 'isEnabledDarkTheme' | 'commonToastErrorMessage'
        >
      >
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    updateUnreadCounters(
      state,
      action: PayloadAction<Record<string, UnreadCounters>>
    ) {
      state.unreadCount = { ...state.unreadCount, ...action.payload };
    },

    increasePresentsRequestsCount(state) {
      state.presentsRequestsCount += 1;
    },

    decreasePresentsRequestsCount(state) {
      if (state.presentsRequestsCount >= 1) state.presentsRequestsCount -= 1;
    },

    setPrevPagePathname(state, action: PayloadAction<string>) {
      state.prevPagePathname = action.payload;
    },

    setCurrentLang(state, action: PayloadAction<string>) {
      state.currentLang = action.payload;
    },

    setNotificationsSound(state, action: PayloadAction<SoundNotification>) {
      state.sound_notification = action.payload;
    },

    setIsEnabledDarkTheme(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        setLocalStorageItem(DARK_THEME, true);
      } else {
        removeLocalStorageItem(DARK_THEME);
      }

      state.isEnabledDarkTheme = action.payload;
    },

    setCommonToastError(state, action: PayloadAction<string | null>) {
      state.commonToastErrorMessage = action.payload;
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setInitState,
  resetState,
  setLoading,
  updateUnreadCounters,
  setPrevPagePathname,
  setCurrentLang,
  setNotificationsSound,
  setIsEnabledDarkTheme,
  increasePresentsRequestsCount,
  decreasePresentsRequestsCount,

  setCommonToastError,
} = commonSlice.actions;

export const common = commonSlice.reducer;
