import { createSelector } from '@reduxjs/toolkit';

import { UnreadCounters } from 'types/interfaces/UnreadCounters';

import { getActiveUserProfileId } from 'store/auth/selectors';
import { RootState } from 'store/rootReducer';

const getTotalUnreadCounters = (state: RootState): UnreadCounters => {
  return Object.values(state.common.unreadCount).reduce(
    (acc, counters) => ({
      inmails: acc.inmails + counters.inmails,
      dialogs: acc.dialogs + counters.dialogs,
      notifications: acc.notifications + counters.notifications,
    }),
    { inmails: 0, dialogs: 0, notifications: 0 }
  );
};

export const getUnreadCounters = (state: RootState) => {
  return state.common.unreadCount;
};

export const getTotalNewDialogsCount = createSelector(
  getTotalUnreadCounters,
  (totalUnreadCounters) => totalUnreadCounters.dialogs
);

export const getNewDialogsCount = createSelector(
  getActiveUserProfileId,
  getUnreadCounters,
  getTotalUnreadCounters,
  (activeUserProfileId, unreadCounters, totalUnreadCounters) => {
    if (activeUserProfileId)
      return unreadCounters[activeUserProfileId]?.dialogs;

    return totalUnreadCounters.dialogs;
  }
);

export const getNewNotificationsCount = createSelector(
  getTotalUnreadCounters,
  (unreadCounters) => unreadCounters.notifications
);

export const getPresentsRequestsCount = (state: RootState) =>
  state.common.presentsRequestsCount;

export const getIsCommonStateLoading = (state: RootState) =>
  state.common.loading;

export const getPrevPagePathname = (state: RootState) =>
  state.common.prevPagePathname;

export const getCurrentLangSelector = (state: RootState) =>
  state.common.currentLang;

export const getSupportedLangsSelector = (state: RootState) =>
  state.common.supportedLangs;

const getFeatureFlagsSelector = (state: RootState) => state.common.featureFlags;

export const getNotificationsSoundSelector = (state: RootState) =>
  state.common.sound_notification;

export const getIsEnabledDarkThemeSelector = (state: RootState) =>
  state.common.isEnabledDarkTheme;

export const getIsEnabledIcebreakersChainsSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledIcebreakersChains
);

export const getCommonToastErrorMessageSelector = (state: RootState) =>
  state.common.commonToastErrorMessage;
