import { AppDispatch, AppThunk } from 'store';

import { MailsTab } from 'types/enums/mails/MailsTab';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsFilters } from 'types/interfaces/mails/MailsFilters';

import { MailsApi } from 'api/MailsApi';
import { getErrorMessageWithDefault } from 'helpers/errors';
import { setCommonToastError } from 'store/common/commonSlice';

import {
  addMail,
  addMails,
  addMailsChain,
  addMailsChains,
  addMailsDraft,
  removeMailsDraft,
  setIsMailsDraftLimitReached,
  setIsMailsDraftLoading,
  setIsMailsDraftsLoading,
  setIsMailsLoading,
  setMails,
  setMailsChains,
  setMailsChainsCounters,
  setMailsChainsLoading,
  setMailsDrafts,
  setNextMailsChains,
  updateMailsChainWithNewMail,
} from './mailsSlice';

export const fetchMailsChainsThunk =
  (payload: MailsFilters & { userId: string | null }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMailsChainsLoading(true));

    const { data: mailsChains, next } =
      await MailsApi.fetchMailsChains(payload);

    dispatch(setMailsChains(mailsChains));
    dispatch(setNextMailsChains(next));
    dispatch(setMailsChainsLoading(false));
  };

export const fetchMoreMailsChainsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { inbox } = getState();
    const nextUrl = inbox.nextMailsChains;

    if (!nextUrl) return;

    const { data: mailsChains, next } =
      await MailsApi.fetchMoreMailsChains(nextUrl);

    dispatch(addMailsChains(mailsChains));
    dispatch(setNextMailsChains(next));
  };

export const fetchMailsChainsCountersThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const { tab_counters: tabCounters } =
      await MailsApi.fetchMailsChainsTabCounters();

    dispatch(setMailsChainsCounters(tabCounters));
  };

export const fetchMailsThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsMailsLoading(true));
    dispatch(setIsMailsDraftLimitReached(false));

    const mailsChainResponse = await MailsApi.fetchMails(payload.contactId);

    dispatch(
      setMails({
        user: mailsChainResponse.user,
        contact: mailsChainResponse.contact,
        messages: mailsChainResponse.inmails,
        next: mailsChainResponse.next,
        limits: mailsChainResponse.limits,
        isLiked: mailsChainResponse.is_liked,
        isBlocked: mailsChainResponse.blocked_me,
        isConnection: mailsChainResponse?.connection_status,
        isEnabledLimitsTest: mailsChainResponse?.is_enabled_media_limits_55,
      })
    );

    dispatch(setIsMailsLoading(false));
  };

export const fetchMoreMailsThunk =
  (payload: { userId: string; contactId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId, userId } = payload;
    const { inbox } = getState();

    const nextUrl = inbox.mails[`${contactId}-${userId}`].next;

    if (!nextUrl) return;

    const mailsChainResponse = await MailsApi.fetchMoreMails(nextUrl);

    dispatch(
      addMails({
        userId,
        contactId,
        messages: mailsChainResponse.inmails,
        next: mailsChainResponse.next,
      })
    );
  };

export const sendTextMailThunk =
  (payload: {
    contactId: string;
    body: string;
    photosIds: number[];
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      await MailsApi.sendTextMail(payload);

      dispatch(setIsMailsDraftLimitReached(false));
    } catch (error: any) {
      dispatch(setCommonToastError(getErrorMessageWithDefault(error)));
    }
  };

export const fetchOneMailThunk =
  (payload: {
    contactId: string;
    userId: string;
    activeProfileId: string | null;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { mailsFilters } = getState().inbox;

    if (
      mailsFilters.tab !== MailsTab.All &&
      (!payload.activeProfileId || payload.activeProfileId === payload.userId)
    ) {
      const { data: newMailsChain } = await MailsApi.fetchOneMailChain({
        contactId: payload.contactId,
        userId: payload.userId,
      });

      if (newMailsChain.id) {
        dispatch(addMailsChain(newMailsChain));
      }
    }
  };

export const addNewMailFromSocketThunk =
  (payload: { message: Mail; userId: string; contactId: string }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { activeProfileId } = getState().auth;
    const { mailsChains } = getState().inbox;

    dispatch(addMail(payload));
    dispatch(
      removeMailsDraft({ contactId: payload.contactId, userId: payload.userId })
    );

    const hasMailsChainWith =
      (contactId: string, userId: string) => (mailsChain: MailsChain) =>
        mailsChain.contact.ulid_id === contactId &&
        mailsChain.user?.ulid_id === userId;

    if (
      !mailsChains.some(hasMailsChainWith(payload.contactId, payload.userId))
    ) {
      dispatch(
        fetchOneMailThunk({
          contactId: payload.contactId,
          userId: payload.userId,
          activeProfileId,
        })
      );
    }

    if (activeProfileId && payload.userId !== activeProfileId) return;

    if (
      mailsChains.some(hasMailsChainWith(payload.contactId, payload.userId))
    ) {
      dispatch(updateMailsChainWithNewMail(payload));
    }
  };

export const fetchMailsDraftsThunk =
  (payload: { userId: string | null }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsMailsDraftsLoading(true));

    try {
      const { data: mailsDrafts } = await MailsApi.fetchMailsDrafts(payload);

      dispatch(setMailsDrafts(mailsDrafts));
    } finally {
      dispatch(setIsMailsDraftsLoading(false));
    }
  };

export const fetchMailsDraftThunk =
  (payload: { contactId: string; userId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsMailsDraftLoading(true));

    const { data: draft } = await MailsApi.fetchMailsDraft(payload.contactId);

    dispatch(addMailsDraft({ ...payload, draft }));
    dispatch(setIsMailsDraftLoading(false));
  };

export const saveMailsDraftThunk =
  (payload: {
    contactId: string;
    body: string;
    photosIds: number[];
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (payload.body) {
        await MailsApi.saveMailsDraft(payload);
      }

      if (!payload.body) {
        await MailsApi.deleteMailsDraft(payload.contactId);

        dispatch(setIsMailsDraftLimitReached(false));
      }
    } catch (error: any) {
      if (error.response.data.result === false)
        dispatch(setIsMailsDraftLimitReached(true));
    }
  };
