import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';

import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { useMedia } from 'hooks/useMedia';
import { getAuthenticated } from 'store/auth/selectors';
import { fetchProfilesThunk } from 'store/auth/thunks';
import { init } from 'store/common/thunks';
import { fetchMailsChainsCountersThunk } from 'store/mails/thunks';

import { FullscreenSpinner } from '../FullscreenSpinner';

interface IProps<T> {
  component: React.ComponentType<T> | LoadableComponent<T>;
  componentProps?: T;
  isPublic?: boolean;
  isPrivate?: boolean;
  restrictedType?: RoutesRestrictionType;
}

export const AppRoute = <T extends object>({
  component: Component,
  componentProps = {} as T,
  isPrivate, // ? only for authorized users
  isPublic, // ? only for non authorized users
  restrictedType,
}: IProps<T>) => {
  const dispatch = useDispatch();

  const { isMobile, isTablet, isDesktop } = useMedia();

  const isAuthenticated = useSelector(getAuthenticated);

  useEffect(() => {
    if (isAuthenticated && isPrivate) {
      dispatch(init());
      dispatch(fetchProfilesThunk());
      dispatch(fetchMailsChainsCountersThunk());
    }
  }, [dispatch, isAuthenticated, isPrivate]);

  if (isPrivate && !isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (isPublic && isAuthenticated) {
    return <Navigate to="/finder" replace />;
  }

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.MobileRestricted &&
    isMobile
  ) {
    return <Navigate to="/finder" replace />;
  }

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.DesktopRestricted &&
    (isDesktop || isTablet)
  ) {
    return <Navigate to="/finder" replace />;
  }

  return (
    <Component {...componentProps} fallback={<FullscreenSpinner fallback />} />
  );
};
