import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsChainsTabCounters } from 'types/interfaces/mails/MailsChainsTabCounters';
import { MailsFilters } from 'types/interfaces/mails/MailsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { httpClient } from './httpClient';

interface MailsChainResponse {
  data: MailsChain[];
  next: string | null;
}

interface MailChainResponse {
  data: MailsChain;
}

interface MailsResponse {
  next: string | null;
  user: UserContact;
  contact: UserContact;
  inmails: Mail[];
  blocked_me: boolean;
  connection_status: boolean;
  is_liked: boolean;
  is_enabled_media_limits_55: boolean;
  limits: ChatLimits;
}

interface SendTextMailsMessagePayload {
  contactId: string;
  body: string;
  photosIds: number[];
}

const readIncomeMails = (message: Mail) => {
  if (!message.is_incoming) {
    return message;
  }

  return {
    ...message,
    read_at: new Date().toUTCString(),
  };
};

export const MailsApi = {
  async fetchMailsChains(payload: MailsFilters & { userId: string | null }) {
    const { data } = await httpClient.get<MailsChainResponse>('/inmails', {
      params: {
        search: payload.search,
        tab: payload.tab,
        x_ulid: payload.userId,
      },
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async fetchMailsChainsTabCounters() {
    const { data } = await httpClient.get<{
      tab_counters: Record<string, MailsChainsTabCounters>;
    }>('/inmails/tab-counters', {
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async fetchMoreMailsChains(nextUrl: string) {
    const { data } = await httpClient.get<MailsChainResponse>(nextUrl, {
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async fetchOneMailChain(payload: { contactId: string; userId: string }) {
    const { data } = await httpClient.get<MailChainResponse>(
      `/inmails/${payload.contactId}/show`,
      {
        headers: {
          'X-ULID': payload.userId,
        },
      }
    );

    return data;
  },

  async fetchMails(contactId: string) {
    const { data } = await httpClient.get<MailsResponse>(
      `/inmails/${contactId}`
    );

    return {
      ...data,
      inmails: data.inmails?.map(readIncomeMails) || [],
    };
  },

  async fetchMoreMails(url: string) {
    const { data } = await httpClient.get<MailsResponse>(url);

    return data;
  },

  async sendTextMail(payload: SendTextMailsMessagePayload) {
    return httpClient.post<{ message: Mail }>('/inmails', {
      body: payload.body,
      receiver_id: payload.contactId,
      photos: payload.photosIds,
    });
  },

  async fetchMailsDrafts(payload: { userId: string | null }) {
    const { data } = await httpClient.get<{ data: MailsChain[] }>(
      '/inmails-draft',
      {
        params: {
          x_ulid: payload.userId,
        },
        headers: {
          'X-ULID': null,
        },
      }
    );

    return data;
  },

  async fetchMailsDraft(contactId: string) {
    const { data } = await httpClient.get<{ data: MailsChain }>(
      `/inmails-draft/${contactId}`
    );

    return data;
  },

  async deleteMailsDraft(contactId: string) {
    return httpClient.delete<{ message: Mail }>(`/inmails-draft/${contactId}`);
  },

  async saveMailsDraft(
    payload: SendTextMailsMessagePayload & { userId?: string }
  ) {
    return httpClient.post<{ message: Mail }>(
      '/inmails-draft',
      {
        body: payload.body,
        receiver_id: payload.contactId,
        photos: payload.photosIds,
      },
      {
        headers: {
          ...(!!payload.userId && {
            'X-ULID': payload.userId,
          }),
        },
      }
    );
  },
};
