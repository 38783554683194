import { createSelector } from 'reselect';

import { MediaAccess } from 'types/interfaces/user/UserProfile';

import { sortArrayByFieldDate } from 'helpers/dates';
import { RootState } from 'store/rootReducer';

export const getProfileDetails = (state: RootState) =>
  state.profile.profileDetails;

export const getProfileOptions = (state: RootState) =>
  state.profile.profileOptions;

export const getProfileSeenMedia =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return contactId && userId
      ? state.profile.profileSeenMedia[`${userId}-${contactId}`]
      : ({} as MediaAccess);
  };

export const getProfileSentMedia =
  ({ contactId, userId }: { contactId: string; userId: string }) =>
  (state: RootState) => {
    return contactId && userId
      ? state.profile.profileSentMedia[`${userId}-${contactId}`]
      : [];
  };

export const getPresentsCategoriesSelector = (state: RootState) =>
  state.profile.presentsCategories;

export const getIsPresentsCategoriesLoadingSelector = (state: RootState) =>
  state.profile.isPresentsCategoriesLoading;

export const getPresentsRequestsSelector = (state: RootState) =>
  state.profile.presentsRequests;

export const getSortedPresentsRequestsSelector = createSelector(
  getPresentsRequestsSelector,
  (presentsRequests) => sortArrayByFieldDate('created_at', presentsRequests)
);

export const getIsPresentsRequestsLoadingSelector = (state: RootState) =>
  state.profile.isPresentsRequestsLoading;

export const getPresentsWishlistSelector = (state: RootState) =>
  state.profile.presentsWishlist;

export const getIsPresentsWishlistLoadingSelector = (state: RootState) =>
  state.profile.isPresentsWishlistLoading;
