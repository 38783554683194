import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { tabBadge } from 'helpers/browserBadge';
import { getTotalNewDialogsCount } from 'store/common/selectors';

export const useBrowserBadge = () => {
  const totalNewDialogsCount = useSelector(getTotalNewDialogsCount);

  useEffect(() => {
    const faviconElement = document.querySelector(
      'link[rel$=icon][sizes="32x32"]'
    );

    tabBadge.updateFaviconEl(faviconElement);
  }, []);

  useEffect(() => {
    tabBadge.updateNotificationCounter(totalNewDialogsCount);
  }, [totalNewDialogsCount]);
};
