import { useCallback } from 'react';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from 'react-query';

import { NoteType } from 'types/enums/NoteType';
import { Note } from 'types/interfaces/Note';

import { NotesApi, NotesResponse } from 'api/NotesApi';

export const CHAT_NOTES_QUERY_KEY = 'chat-notes-query';

export const useChatNotesQuery = (
  payload: {
    contactId: string;
    userId: string;
    noteType: NoteType;
  },
  queryOptions?: UseInfiniteQueryOptions<NotesResponse>
) => {
  return useInfiniteQuery<NotesResponse>({
    queryKey: [CHAT_NOTES_QUERY_KEY, payload.contactId, payload.userId],
    queryFn: ({ pageParam = '' }) => {
      return NotesApi.fetchNotes({
        contactId: payload.contactId,
        next: pageParam,
        noteType: payload.noteType,
      });
    },
    getNextPageParam: (lastPage) => lastPage?.links?.next,
    cacheTime: 0,
    retry: false,
    ...queryOptions,
  });
};

export const useChatNotesQueryCache = ({
  contactId,
  userId,
}: {
  contactId: string;
  userId: string;
}) => {
  const queryClient = useQueryClient();

  const getNotesCache = useCallback(() => {
    return queryClient.getQueryData<InfiniteData<NotesResponse>>([
      CHAT_NOTES_QUERY_KEY,
      contactId,
      userId,
    ]);
  }, [contactId, queryClient, userId]);

  const addNewNote = useCallback(
    (newNote: Note) => {
      const notesCache = getNotesCache();

      if (notesCache && notesCache?.pages?.[0]) {
        const updatedPage = {
          ...(notesCache.pages?.[0] || {}),
          data: [newNote, ...(notesCache.pages[0]?.data || [])],
        };

        queryClient.setQueryData<InfiniteData<NotesResponse>>(
          [CHAT_NOTES_QUERY_KEY, contactId, userId],
          () => {
            return {
              ...notesCache,
              pages: [updatedPage, ...notesCache.pages.slice(1)],
            };
          }
        );
      }
    },
    [contactId, getNotesCache, queryClient, userId]
  );

  const updateNote = useCallback(
    (updatedNoteId: number, updatedNote: Partial<Note>) => {
      const notesCache = getNotesCache();
      if (notesCache && notesCache?.pages?.length) {
        const updatedNotesPages = notesCache.pages.map((pageItem) => {
          return {
            ...(pageItem || {}),
            data: (pageItem?.data || []).map((noteItem) => {
              if (noteItem.id === updatedNoteId) {
                return { ...noteItem, ...updatedNote };
              }

              return noteItem;
            }),
          };
        });

        queryClient.setQueryData<InfiniteData<NotesResponse>>(
          [CHAT_NOTES_QUERY_KEY, contactId, userId],
          () => {
            return {
              ...notesCache,
              pages: updatedNotesPages,
            };
          }
        );
      }
    },
    [contactId, getNotesCache, queryClient, userId]
  );

  return { addNewNote, updateNote };
};
