import React, { memo, useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NoteType } from 'types/enums/NoteType';
import { Note } from 'types/interfaces/Note';

import { TrackingApi } from 'api/TrackingApi';
import {
  useChatNotesQuery,
  useChatNotesQueryCache,
} from 'hooks/queries/useChatNotesQuery';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-outline.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Spinner, SpinnerTypes } from 'components/base/Spinner';
import { LoadableContent } from 'components/shared/LoadableContent';

import { CreateNotePopup } from '../CreateNotePopup';
import { NoteItem } from '../NoteItem';

import css from './notesTab.module.sass';

interface Props {
  contactId: string;
  userProfileUlid: string;
  noteType: NoteType;
}

export const NotesTab: React.FC<Props> = memo(
  ({ contactId, userProfileUlid, noteType }) => {
    const [isOpenedCreateNotePopup, setIsOpenedCreateNotePopup] =
      useState(false);

    const openCreateNotePopup = useCallback(() => {
      setIsOpenedCreateNotePopup(true);

      TrackingApi.trackWidget({
        widgetName: 'contact',
        eventName: 'click',
        clickOn: 'add_note',
        tabName: 'notes',
        contactId,
      });
    }, [contactId]);

    const onNoteEdit = useCallback(() => {
      TrackingApi.trackWidget({
        widgetName: 'contact',
        eventName: 'click',
        clickOn: 'edit_note',
        tabName: 'notes',
        contactId,
      });
    }, [contactId]);

    const closeCreateNotePopup = useCallback(() => {
      setIsOpenedCreateNotePopup(false);
    }, []);

    const {
      data,
      isLoading: isNotesLoading,
      isFetching: isNotesFetching,
      hasNextPage,
      fetchNextPage,
    } = useChatNotesQuery(
      {
        contactId,
        userId: userProfileUlid,
        noteType,
      },
      { enabled: !!userProfileUlid && !!contactId }
    );

    const { addNewNote, updateNote } = useChatNotesQueryCache({
      contactId,
      userId: userProfileUlid,
    });

    const notes = useMemo(() => {
      return (data?.pages || [])
        .reduce((acc: Note[], page) => {
          return [...acc, ...page.data];
        }, [])
        .sort((leftNote, rightNote) => rightNote.sort - leftNote.sort);
    }, [data]);

    return (
      <div className={css.root}>
        <BaseButton
          className={css.addBtn}
          type={ButtonTypes.Secondary}
          onClick={openCreateNotePopup}
          disabled={isNotesFetching}
        >
          <PlusIcon className={css.icon} />
          Add new note
        </BaseButton>

        {!isNotesFetching && !notes.length && (
          <div className={css.box}>You don&apos;t have any notes yet!</div>
        )}

        <LoadableContent
          loading={isNotesLoading}
          loader={
            <div key="spinner" className={css.box}>
              <Spinner type={SpinnerTypes.Dark} size="25px" />
            </div>
          }
        >
          <InfiniteScroll
            scrollableTarget="notesScrollContainer"
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            dataLength={notes?.length}
            loader={
              <div key="spinner" className={css.box}>
                <Spinner type={SpinnerTypes.Dark} size="25px" />
              </div>
            }
            className={css.list}
          >
            {notes?.map((noteItem: Note, index, notesArray) => (
              <NoteItem
                key={noteItem.id}
                noteItem={noteItem}
                prevNoteItemId={notesArray?.[index + 1]?.id || null}
                nextNoteItemId={notesArray?.[index - 1]?.id || null}
                updateNote={updateNote}
                onEdit={onNoteEdit}
                isFirst={index === 0}
                isLast={index === notesArray.length - 1}
              />
            ))}
          </InfiniteScroll>
        </LoadableContent>

        {isOpenedCreateNotePopup && (
          <CreateNotePopup
            noteType={noteType}
            contactId={contactId}
            onSubmit={addNewNote}
            onClose={closeCreateNotePopup}
          />
        )}
      </div>
    );
  }
);
