import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setActiveProfileId } from 'store/auth/authSlice';
import { getActiveUserProfile } from 'store/auth/selectors';
import {
  ProfileSelectParams,
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

export const useActiveUserSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeUserProfile = useSelector(getActiveUserProfile);

  const handleOpenProfilesPopup = ({
    params,
  }: {
    params: ProfileSelectParams;
  }) => {
    if (params.isMain && activeUserProfile && params.destinationPage) {
      navigate(params.destinationPage);

      return;
    }

    dispatch(
      setActiveSystemPopup({
        type: SystemPopupTypes.ProfileSelect,
        params,
      })
    );
  };

  const handleActiveUserSelect = ({
    userId,
    path,
  }: {
    userId: string | null;
    path: string | null;
  }) => {
    dispatch(setActiveProfileId(userId));

    if (path) {
      navigate(`${path}?x_ulid=${userId}`);
    }
  };

  return { handleOpenProfilesPopup, handleActiveUserSelect };
};
