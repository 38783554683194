import { TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY } from 'helpers/constants';
import { getScreenOrientationWithSizes } from 'helpers/device';
import { getIsEnabledUserAvatar } from 'helpers/profiles';
import { getSessionStorageItem } from 'helpers/sessionStorage';

import { httpClient } from './httpClient';

export interface WidgetTrackingPayload {
  eventName: 'click' | 'close' | 'show';
  clickOn:
    | 'tab_contact'
    | 'tab_my_profile'
    | 'tab_notes'
    | 'add_note'
    | 'edit_note'
    | 'user'
    | string
    | number
    | null;
  widgetName:
    | 'contact'
    | 'activity'
    | 'presents_request'
    | 'tu_profile_switcher';
  contactId?: string;
  tabName?: 'contact' | 'notes' | 'my_profile';
}

const getBuildVersionFromSessionStorage = () => {
  return (
    getSessionStorageItem(TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY) || '0'
  );
};

export const TrackingApi = {
  trackError(payload: { message: string; params?: any }) {
    return httpClient.post('/log/error', {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  trackWarning(payload: { message: string; params?: any }) {
    return httpClient.post('/log/warning', {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  trackPageLoad(payload: {
    url: string;
    currentUserId: string;
    interactionWithUserId?: string;
  }) {
    const { orientation, width, height } = getScreenOrientationWithSizes();

    return httpClient.post(
      '/track/events/page-load',
      {
        url: payload.url,
        pageLoadId: window.pageLoadId,
        screenOrientation: orientation,
        screenHeight: height,
        screenWidth: width,
        source: getIsEnabledUserAvatar() ? 'extension' : 'chatshouse',
        ...(!!payload.interactionWithUserId && {
          interactionWithUserId: payload.interactionWithUserId || '',
        }),
      },
      {
        headers: {
          ...(!!payload.currentUserId && {
            'X-ULID': payload.currentUserId || '',
          }),
        },
      }
    );
  },

  trackWidget(payload: WidgetTrackingPayload) {
    const { contactId, eventName, clickOn, tabName, widgetName } = payload;

    return httpClient.post('/track/widget', {
      contact_id: contactId,
      event: eventName,
      click_on: clickOn,
      tab_name: tabName,
      widget_name: widgetName,
      url: window.location.href,
      page_load_id: window?.pageLoadId,
    });
  },

  trackMessageReceived(payload: { messageId: string; contactId: string }) {
    return httpClient.get(
      `/track/message-received/${payload.messageId}/${payload.contactId}`
    );
  },

  trackRequestReceived(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-received/${payload.requestId}`
    );
  },

  trackMessageSent(payload: { messageId: string; contactId: string }) {
    return httpClient.get(
      `/track/message-sent/${payload.messageId}/${payload.contactId}`
    );
  },

  trackRequestSent(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-sent/${payload.requestId}`
    );
  },
};
