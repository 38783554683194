import { AppDispatch, AppThunk } from 'store';

import { PresentStatus } from 'types/enums/presents/PresentStatus';

import { MediaApi } from 'api/MediaApi';
import { LikePayload, SympathiesApi } from 'api/SympathiesApi';
import { UserProfileApi } from 'api/UserProfileApi';
import {
  decreasePresentsRequestsCount,
  setCommonToastError,
} from 'store/common/commonSlice';
import { likeUser } from 'store/finder/finderSlice';
import { likeMailsChainContact } from 'store/mails/mailsSlice';
import { likeChatContact } from 'store/messenger/messengerSlice';

import {
  addPresentToWishlist,
  removePresentFromWishlist,
  setIsPresentsCategoriesLoading,
  setIsPresentsRequestsLoading,
  setIsPresentsWishlistLoading,
  setPresentsCategories,
  setPresentsRequests,
  setPresentsWishlist,
  setProfileDetails,
  setProfileOptions,
  setProfileSeenMedia,
  setProfileSentMedia,
  updatePresentRequest,
} from './profileSlice';

export const fetchUserProfileDetailsThunk =
  (): AppThunk => async (dispatch) => {
    const { data: details, options: detailOptions } =
      await UserProfileApi.fetchUserDetails();

    dispatch(setProfileDetails(details));
    dispatch(setProfileOptions(detailOptions));
  };

export const fetchSeenMediaThunk =
  (payload: { contactId: string; userId: string }) =>
  async (dispatch: AppDispatch) => {
    const { data } = await MediaApi.getMediaAccesses(payload.contactId);

    dispatch(
      setProfileSeenMedia({
        userId: payload.userId,
        contactId: payload.contactId,
        access: data,
      })
    );
  };

export const fetchSentMediaThunk =
  (payload: { contactId: string; userId: string }) =>
  async (dispatch: AppDispatch) => {
    const { data } = await MediaApi.getSentMedia(payload.contactId);

    dispatch(
      setProfileSentMedia({
        userId: payload.userId,
        contactId: payload.contactId,
        sentMedia: [...(data.inmails || []), ...(data.messages || [])],
      })
    );
  };

export const getPresentsCategoriesThunk =
  () => async (dispatch: AppDispatch) => {
    dispatch(setIsPresentsCategoriesLoading(true));

    try {
      const { data: presentsCategoriesResponse } =
        await UserProfileApi.getPresentsCategories();

      dispatch(setPresentsCategories(presentsCategoriesResponse));
      dispatch(setIsPresentsCategoriesLoading(false));
    } catch (error) {
      dispatch(setIsPresentsCategoriesLoading(false));
    }
  };

export const getPresentsRequestsThunk = () => async (dispatch: AppDispatch) => {
  dispatch(setIsPresentsRequestsLoading(true));

  const { data: presentsRequests } = await UserProfileApi.getPresentsRequests();

  dispatch(setPresentsRequests(presentsRequests));
  dispatch(setIsPresentsRequestsLoading(false));
};

export const getPresentsWishlistThunk =
  (userId: string) => async (dispatch: AppDispatch) => {
    dispatch(setIsPresentsWishlistLoading(true));

    const { data: presentsWishlistResponse } =
      await UserProfileApi.getPresentsWishlist({ userId });

    dispatch(setPresentsWishlist(presentsWishlistResponse));
    dispatch(setIsPresentsWishlistLoading(false));
  };

export const addPresentToWishlistThunk =
  (payload: { presentId: number; userId: string }) =>
  async (dispatch: AppDispatch) => {
    const newPresent = await UserProfileApi.addPresentToWishlist(payload);

    if (!newPresent.gift) return;

    dispatch(addPresentToWishlist(newPresent));
  };

export const removePresentFromWishlistThunk =
  (payload: { presentInfoId: number; userId: string }) =>
  async (dispatch: AppDispatch) => {
    await UserProfileApi.removePresentFromWishlist(payload);

    dispatch(
      removePresentFromWishlist({ presentInfoId: payload.presentInfoId })
    );
  };

export const updatePresentRequestStatusThunk =
  (payload: {
    status: PresentStatus;
    presentRequestId: number;
    presentReceiverId: string;
  }) =>
  async (dispatch: AppDispatch) => {
    const { result } = await UserProfileApi.updatePresentRequestStatus(payload);

    if (!result) return;

    dispatch(
      updatePresentRequest({
        id: payload.presentRequestId,
        status: payload.status,
      })
    );

    if (payload.status !== PresentStatus.Received) {
      dispatch(decreasePresentsRequestsCount());
    }
  };

export const likeUserProfileThunk =
  (payload: LikePayload) => async (dispatch: AppDispatch) => {
    dispatch(likeUser({ userId: payload.receiverId, isLiked: true }));
    dispatch(
      likeChatContact({
        userId: payload.userId,
        contactId: payload.receiverId,
        isLiked: true,
      })
    );
    dispatch(
      likeMailsChainContact({
        userId: payload.userId,
        contactId: payload.receiverId,
        isLiked: true,
      })
    );

    try {
      await SympathiesApi.like(payload);
    } catch (error: any) {
      dispatch(
        setCommonToastError(
          error?.response?.data?.receiver_id?.[0] ||
            "Oops, contact wasn't liked!"
        )
      );

      dispatch(likeUser({ userId: payload.receiverId, isLiked: false }));
      dispatch(
        likeChatContact({
          userId: payload.userId,
          contactId: payload.receiverId,
          isLiked: false,
        })
      );
      dispatch(
        likeMailsChainContact({
          userId: payload.userId,
          contactId: payload.receiverId,
          isLiked: false,
        })
      );
    }
  };
